// stylelint-disable declaration-no-important

//
// Text
//

.text-monospace { font-family: $font-family-monospace; }

// Alignment

.text-justify  { text-align: justify !important; }
.text-nowrap   { white-space: nowrap !important; }
.text-truncate { @include text-truncate; }

// Responsive alignment

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .text#{$infix}-left   { text-align: left !important; }
    .text#{$infix}-right  { text-align: right !important; }
    .text#{$infix}-center { text-align: center !important; }
  }
}

// Transformation

.text-lowercase  { text-transform: lowercase !important; }
.text-uppercase  { text-transform: uppercase !important; }
.text-capitalize { text-transform: capitalize !important; }

// Weight and italics

.font-weight-light  { font-weight: $font-weight-light !important; }
.font-weight-normal { font-weight: $font-weight-normal !important; }
.font-weight-semibold   { font-weight: $font-weight-semibold !important; }
.font-weight-bold   { font-weight: $font-weight-bold !important; }
.font-italic        { font-style: italic !important; }

// Size

.font-size-x-small { font-size: $font-size-xs; }
.font-size-small { font-size: $font-size-sm; }
.font-size-normal { font-size: $font-size-base; }
.font-size-medium {
  font-size: $font-size-md;
  line-height: 120%;
}
.font-size-large { font-size: $font-size-lg; }
.font-size-x-large { font-size: $font-size-xl; }
.font-size-x-large-2 { font-size: $font-size-xxl; }
.font-size-x-large-3 {
  font-size: $font-size-xxl2;
  line-height: 120%;
}

// Contextual colors

.text-white { color: $white !important; }

@each $color, $value in $theme-colors {
  @include text-emphasis-variant(".text-#{$color}", $value);
}

.text-body { color: $body-color !important; }
.text-muted { color: $text-muted !important; }

.text-black-50 { color: rgba($black, .5) !important; }
.text-white-50 { color: rgba($white, .5) !important; }

// Misc

.text-hide {
  @include text-hide($ignore-warning: true);
}

//decorations

.text-overline { text-decoration: overline; }
.text-line-through { text-decoration: line-through; }
.text-underline { text-decoration: underline; }
