/*!
 * Bootstrap v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #1a4f9a;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #d9252b;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --silver: #8d9fb2;
  --gray: #6c757d;
  --gray-dark: #282828;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --dark-blue: #133a72;
  --blue: #1a4f9a;
  --blue-opacity: rgba(26, 79, 154, 0.25);
  --warning: #ffc107;
  --danger: #d9252b;
  --light: #f5f8fc;
  --silver: #8d9fb2;
  --dark: #343a40;
  --darkest: #282828;
  --black: #000;
  --opacity: rgba(255, 255, 255, 0);
  --opacity-25: rgba(255, 255, 255, 0.25);
  --opacity-50: rgba(255, 255, 255, 0.5);
  --opacity-75: rgba(255, 255, 255, 0.75);
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1600px;
  --font-family-sans-serif: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@-ms-viewport {
  width: device-width;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h5 .title {
  font-weight: 500;
  text-transform: initial;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #1a4f9a;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #0f2d59;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 600;
  line-height: 1.2;
  color: #000;
}

h1, .h1 {
  font-size: 2.25rem;
  line-height: 1.1675;
}

h2, .h2 {
  font-size: 1.5rem;
  line-height: 1.1675;
}

h3, .h3 {
  font-size: 1.125rem;
  line-height: 1.1675;
}

h4, .h4 {
  font-size: 1rem;
  line-height: 1.1675;
}

h5, .h5 {
  font-size: 0.875rem;
  line-height: 1.1675;
}

h6, .h6 {
  font-size: 0.75rem;
  line-height: 1.1675;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014 \00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}

@media (min-width: 1600px) {
  .container {
    max-width: 1530px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-13, .col-14, .col-15, .col-16, .col-17, .col-18, .col-19, .col-20, .col-21, .col-22, .col-23, .col-24, .col-25, .col-26, .col-27, .col-28, .col-29, .col-30, .col-31, .col-32, .col-33, .col-34, .col-35, .col-36, .col-37, .col-38, .col-39, .col-40, .col-41, .col-42, .col-43, .col-44, .col-45, .col-46, .col-47, .col-48, .col-49, .col-50, .col-51, .col-52, .col-53, .col-54, .col-55, .col-56, .col-57, .col-58, .col-59, .col-60, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-13, .col-sm-14, .col-sm-15, .col-sm-16, .col-sm-17, .col-sm-18, .col-sm-19, .col-sm-20, .col-sm-21, .col-sm-22, .col-sm-23, .col-sm-24, .col-sm-25, .col-sm-26, .col-sm-27, .col-sm-28, .col-sm-29, .col-sm-30, .col-sm-31, .col-sm-32, .col-sm-33, .col-sm-34, .col-sm-35, .col-sm-36, .col-sm-37, .col-sm-38, .col-sm-39, .col-sm-40, .col-sm-41, .col-sm-42, .col-sm-43, .col-sm-44, .col-sm-45, .col-sm-46, .col-sm-47, .col-sm-48, .col-sm-49, .col-sm-50, .col-sm-51, .col-sm-52, .col-sm-53, .col-sm-54, .col-sm-55, .col-sm-56, .col-sm-57, .col-sm-58, .col-sm-59, .col-sm-60, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-13, .col-md-14, .col-md-15, .col-md-16, .col-md-17, .col-md-18, .col-md-19, .col-md-20, .col-md-21, .col-md-22, .col-md-23, .col-md-24, .col-md-25, .col-md-26, .col-md-27, .col-md-28, .col-md-29, .col-md-30, .col-md-31, .col-md-32, .col-md-33, .col-md-34, .col-md-35, .col-md-36, .col-md-37, .col-md-38, .col-md-39, .col-md-40, .col-md-41, .col-md-42, .col-md-43, .col-md-44, .col-md-45, .col-md-46, .col-md-47, .col-md-48, .col-md-49, .col-md-50, .col-md-51, .col-md-52, .col-md-53, .col-md-54, .col-md-55, .col-md-56, .col-md-57, .col-md-58, .col-md-59, .col-md-60, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-13, .col-lg-14, .col-lg-15, .col-lg-16, .col-lg-17, .col-lg-18, .col-lg-19, .col-lg-20, .col-lg-21, .col-lg-22, .col-lg-23, .col-lg-24, .col-lg-25, .col-lg-26, .col-lg-27, .col-lg-28, .col-lg-29, .col-lg-30, .col-lg-31, .col-lg-32, .col-lg-33, .col-lg-34, .col-lg-35, .col-lg-36, .col-lg-37, .col-lg-38, .col-lg-39, .col-lg-40, .col-lg-41, .col-lg-42, .col-lg-43, .col-lg-44, .col-lg-45, .col-lg-46, .col-lg-47, .col-lg-48, .col-lg-49, .col-lg-50, .col-lg-51, .col-lg-52, .col-lg-53, .col-lg-54, .col-lg-55, .col-lg-56, .col-lg-57, .col-lg-58, .col-lg-59, .col-lg-60, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-13, .col-xl-14, .col-xl-15, .col-xl-16, .col-xl-17, .col-xl-18, .col-xl-19, .col-xl-20, .col-xl-21, .col-xl-22, .col-xl-23, .col-xl-24, .col-xl-25, .col-xl-26, .col-xl-27, .col-xl-28, .col-xl-29, .col-xl-30, .col-xl-31, .col-xl-32, .col-xl-33, .col-xl-34, .col-xl-35, .col-xl-36, .col-xl-37, .col-xl-38, .col-xl-39, .col-xl-40, .col-xl-41, .col-xl-42, .col-xl-43, .col-xl-44, .col-xl-45, .col-xl-46, .col-xl-47, .col-xl-48, .col-xl-49, .col-xl-50, .col-xl-51, .col-xl-52, .col-xl-53, .col-xl-54, .col-xl-55, .col-xl-56, .col-xl-57, .col-xl-58, .col-xl-59, .col-xl-60, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl-13, .col-xxl-14, .col-xxl-15, .col-xxl-16, .col-xxl-17, .col-xxl-18, .col-xxl-19, .col-xxl-20, .col-xxl-21, .col-xxl-22, .col-xxl-23, .col-xxl-24, .col-xxl-25, .col-xxl-26, .col-xxl-27, .col-xxl-28, .col-xxl-29, .col-xxl-30, .col-xxl-31, .col-xxl-32, .col-xxl-33, .col-xxl-34, .col-xxl-35, .col-xxl-36, .col-xxl-37, .col-xxl-38, .col-xxl-39, .col-xxl-40, .col-xxl-41, .col-xxl-42, .col-xxl-43, .col-xxl-44, .col-xxl-45, .col-xxl-46, .col-xxl-47, .col-xxl-48, .col-xxl-49, .col-xxl-50, .col-xxl-51, .col-xxl-52, .col-xxl-53, .col-xxl-54, .col-xxl-55, .col-xxl-56, .col-xxl-57, .col-xxl-58, .col-xxl-59, .col-xxl-60, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 1.666667%;
  max-width: 1.666667%;
}

.col-2 {
  flex: 0 0 3.333333%;
  max-width: 3.333333%;
}

.col-3 {
  flex: 0 0 5%;
  max-width: 5%;
}

.col-4 {
  flex: 0 0 6.666667%;
  max-width: 6.666667%;
}

.col-5 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-6 {
  flex: 0 0 10%;
  max-width: 10%;
}

.col-7 {
  flex: 0 0 11.666667%;
  max-width: 11.666667%;
}

.col-8 {
  flex: 0 0 13.333333%;
  max-width: 13.333333%;
}

.col-9 {
  flex: 0 0 15%;
  max-width: 15%;
}

.col-10 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-11 {
  flex: 0 0 18.333333%;
  max-width: 18.333333%;
}

.col-12 {
  flex: 0 0 20%;
  max-width: 20%;
}

.col-13 {
  flex: 0 0 21.666667%;
  max-width: 21.666667%;
}

.col-14 {
  flex: 0 0 23.333333%;
  max-width: 23.333333%;
}

.col-15 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-16 {
  flex: 0 0 26.666667%;
  max-width: 26.666667%;
}

.col-17 {
  flex: 0 0 28.333333%;
  max-width: 28.333333%;
}

.col-18 {
  flex: 0 0 30%;
  max-width: 30%;
}

.col-19 {
  flex: 0 0 31.666667%;
  max-width: 31.666667%;
}

.col-20 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-21 {
  flex: 0 0 35%;
  max-width: 35%;
}

.col-22 {
  flex: 0 0 36.666667%;
  max-width: 36.666667%;
}

.col-23 {
  flex: 0 0 38.333333%;
  max-width: 38.333333%;
}

.col-24 {
  flex: 0 0 40%;
  max-width: 40%;
}

.col-25 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-26 {
  flex: 0 0 43.333333%;
  max-width: 43.333333%;
}

.col-27 {
  flex: 0 0 45%;
  max-width: 45%;
}

.col-28 {
  flex: 0 0 46.666667%;
  max-width: 46.666667%;
}

.col-29 {
  flex: 0 0 48.333333%;
  max-width: 48.333333%;
}

.col-30 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-31 {
  flex: 0 0 51.666667%;
  max-width: 51.666667%;
}

.col-32 {
  flex: 0 0 53.333333%;
  max-width: 53.333333%;
}

.col-33 {
  flex: 0 0 55%;
  max-width: 55%;
}

.col-34 {
  flex: 0 0 56.666667%;
  max-width: 56.666667%;
}

.col-35 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-36 {
  flex: 0 0 60%;
  max-width: 60%;
}

.col-37 {
  flex: 0 0 61.666667%;
  max-width: 61.666667%;
}

.col-38 {
  flex: 0 0 63.333333%;
  max-width: 63.333333%;
}

.col-39 {
  flex: 0 0 65%;
  max-width: 65%;
}

.col-40 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-41 {
  flex: 0 0 68.333333%;
  max-width: 68.333333%;
}

.col-42 {
  flex: 0 0 70%;
  max-width: 70%;
}

.col-43 {
  flex: 0 0 71.666667%;
  max-width: 71.666667%;
}

.col-44 {
  flex: 0 0 73.333333%;
  max-width: 73.333333%;
}

.col-45 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-46 {
  flex: 0 0 76.666667%;
  max-width: 76.666667%;
}

.col-47 {
  flex: 0 0 78.333333%;
  max-width: 78.333333%;
}

.col-48 {
  flex: 0 0 80%;
  max-width: 80%;
}

.col-49 {
  flex: 0 0 81.666667%;
  max-width: 81.666667%;
}

.col-50 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-51 {
  flex: 0 0 85%;
  max-width: 85%;
}

.col-52 {
  flex: 0 0 86.666667%;
  max-width: 86.666667%;
}

.col-53 {
  flex: 0 0 88.333333%;
  max-width: 88.333333%;
}

.col-54 {
  flex: 0 0 90%;
  max-width: 90%;
}

.col-55 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-56 {
  flex: 0 0 93.333333%;
  max-width: 93.333333%;
}

.col-57 {
  flex: 0 0 95%;
  max-width: 95%;
}

.col-58 {
  flex: 0 0 96.666667%;
  max-width: 96.666667%;
}

.col-59 {
  flex: 0 0 98.333333%;
  max-width: 98.333333%;
}

.col-60 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 61;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.order-13 {
  order: 13;
}

.order-14 {
  order: 14;
}

.order-15 {
  order: 15;
}

.order-16 {
  order: 16;
}

.order-17 {
  order: 17;
}

.order-18 {
  order: 18;
}

.order-19 {
  order: 19;
}

.order-20 {
  order: 20;
}

.order-21 {
  order: 21;
}

.order-22 {
  order: 22;
}

.order-23 {
  order: 23;
}

.order-24 {
  order: 24;
}

.order-25 {
  order: 25;
}

.order-26 {
  order: 26;
}

.order-27 {
  order: 27;
}

.order-28 {
  order: 28;
}

.order-29 {
  order: 29;
}

.order-30 {
  order: 30;
}

.order-31 {
  order: 31;
}

.order-32 {
  order: 32;
}

.order-33 {
  order: 33;
}

.order-34 {
  order: 34;
}

.order-35 {
  order: 35;
}

.order-36 {
  order: 36;
}

.order-37 {
  order: 37;
}

.order-38 {
  order: 38;
}

.order-39 {
  order: 39;
}

.order-40 {
  order: 40;
}

.order-41 {
  order: 41;
}

.order-42 {
  order: 42;
}

.order-43 {
  order: 43;
}

.order-44 {
  order: 44;
}

.order-45 {
  order: 45;
}

.order-46 {
  order: 46;
}

.order-47 {
  order: 47;
}

.order-48 {
  order: 48;
}

.order-49 {
  order: 49;
}

.order-50 {
  order: 50;
}

.order-51 {
  order: 51;
}

.order-52 {
  order: 52;
}

.order-53 {
  order: 53;
}

.order-54 {
  order: 54;
}

.order-55 {
  order: 55;
}

.order-56 {
  order: 56;
}

.order-57 {
  order: 57;
}

.order-58 {
  order: 58;
}

.order-59 {
  order: 59;
}

.order-60 {
  order: 60;
}

.offset-1 {
  margin-left: 1.666667%;
}

.offset-2 {
  margin-left: 3.333333%;
}

.offset-3 {
  margin-left: 5%;
}

.offset-4 {
  margin-left: 6.666667%;
}

.offset-5 {
  margin-left: 8.333333%;
}

.offset-6 {
  margin-left: 10%;
}

.offset-7 {
  margin-left: 11.666667%;
}

.offset-8 {
  margin-left: 13.333333%;
}

.offset-9 {
  margin-left: 15%;
}

.offset-10 {
  margin-left: 16.666667%;
}

.offset-11 {
  margin-left: 18.333333%;
}

.offset-12 {
  margin-left: 20%;
}

.offset-13 {
  margin-left: 21.666667%;
}

.offset-14 {
  margin-left: 23.333333%;
}

.offset-15 {
  margin-left: 25%;
}

.offset-16 {
  margin-left: 26.666667%;
}

.offset-17 {
  margin-left: 28.333333%;
}

.offset-18 {
  margin-left: 30%;
}

.offset-19 {
  margin-left: 31.666667%;
}

.offset-20 {
  margin-left: 33.333333%;
}

.offset-21 {
  margin-left: 35%;
}

.offset-22 {
  margin-left: 36.666667%;
}

.offset-23 {
  margin-left: 38.333333%;
}

.offset-24 {
  margin-left: 40%;
}

.offset-25 {
  margin-left: 41.666667%;
}

.offset-26 {
  margin-left: 43.333333%;
}

.offset-27 {
  margin-left: 45%;
}

.offset-28 {
  margin-left: 46.666667%;
}

.offset-29 {
  margin-left: 48.333333%;
}

.offset-30 {
  margin-left: 50%;
}

.offset-31 {
  margin-left: 51.666667%;
}

.offset-32 {
  margin-left: 53.333333%;
}

.offset-33 {
  margin-left: 55%;
}

.offset-34 {
  margin-left: 56.666667%;
}

.offset-35 {
  margin-left: 58.333333%;
}

.offset-36 {
  margin-left: 60%;
}

.offset-37 {
  margin-left: 61.666667%;
}

.offset-38 {
  margin-left: 63.333333%;
}

.offset-39 {
  margin-left: 65%;
}

.offset-40 {
  margin-left: 66.666667%;
}

.offset-41 {
  margin-left: 68.333333%;
}

.offset-42 {
  margin-left: 70%;
}

.offset-43 {
  margin-left: 71.666667%;
}

.offset-44 {
  margin-left: 73.333333%;
}

.offset-45 {
  margin-left: 75%;
}

.offset-46 {
  margin-left: 76.666667%;
}

.offset-47 {
  margin-left: 78.333333%;
}

.offset-48 {
  margin-left: 80%;
}

.offset-49 {
  margin-left: 81.666667%;
}

.offset-50 {
  margin-left: 83.333333%;
}

.offset-51 {
  margin-left: 85%;
}

.offset-52 {
  margin-left: 86.666667%;
}

.offset-53 {
  margin-left: 88.333333%;
}

.offset-54 {
  margin-left: 90%;
}

.offset-55 {
  margin-left: 91.666667%;
}

.offset-56 {
  margin-left: 93.333333%;
}

.offset-57 {
  margin-left: 95%;
}

.offset-58 {
  margin-left: 96.666667%;
}

.offset-59 {
  margin-left: 98.333333%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    flex: 0 0 1.666667%;
    max-width: 1.666667%;
  }
  .col-sm-2 {
    flex: 0 0 3.333333%;
    max-width: 3.333333%;
  }
  .col-sm-3 {
    flex: 0 0 5%;
    max-width: 5%;
  }
  .col-sm-4 {
    flex: 0 0 6.666667%;
    max-width: 6.666667%;
  }
  .col-sm-5 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-6 {
    flex: 0 0 10%;
    max-width: 10%;
  }
  .col-sm-7 {
    flex: 0 0 11.666667%;
    max-width: 11.666667%;
  }
  .col-sm-8 {
    flex: 0 0 13.333333%;
    max-width: 13.333333%;
  }
  .col-sm-9 {
    flex: 0 0 15%;
    max-width: 15%;
  }
  .col-sm-10 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-11 {
    flex: 0 0 18.333333%;
    max-width: 18.333333%;
  }
  .col-sm-12 {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .col-sm-13 {
    flex: 0 0 21.666667%;
    max-width: 21.666667%;
  }
  .col-sm-14 {
    flex: 0 0 23.333333%;
    max-width: 23.333333%;
  }
  .col-sm-15 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-16 {
    flex: 0 0 26.666667%;
    max-width: 26.666667%;
  }
  .col-sm-17 {
    flex: 0 0 28.333333%;
    max-width: 28.333333%;
  }
  .col-sm-18 {
    flex: 0 0 30%;
    max-width: 30%;
  }
  .col-sm-19 {
    flex: 0 0 31.666667%;
    max-width: 31.666667%;
  }
  .col-sm-20 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-21 {
    flex: 0 0 35%;
    max-width: 35%;
  }
  .col-sm-22 {
    flex: 0 0 36.666667%;
    max-width: 36.666667%;
  }
  .col-sm-23 {
    flex: 0 0 38.333333%;
    max-width: 38.333333%;
  }
  .col-sm-24 {
    flex: 0 0 40%;
    max-width: 40%;
  }
  .col-sm-25 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-26 {
    flex: 0 0 43.333333%;
    max-width: 43.333333%;
  }
  .col-sm-27 {
    flex: 0 0 45%;
    max-width: 45%;
  }
  .col-sm-28 {
    flex: 0 0 46.666667%;
    max-width: 46.666667%;
  }
  .col-sm-29 {
    flex: 0 0 48.333333%;
    max-width: 48.333333%;
  }
  .col-sm-30 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-31 {
    flex: 0 0 51.666667%;
    max-width: 51.666667%;
  }
  .col-sm-32 {
    flex: 0 0 53.333333%;
    max-width: 53.333333%;
  }
  .col-sm-33 {
    flex: 0 0 55%;
    max-width: 55%;
  }
  .col-sm-34 {
    flex: 0 0 56.666667%;
    max-width: 56.666667%;
  }
  .col-sm-35 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-36 {
    flex: 0 0 60%;
    max-width: 60%;
  }
  .col-sm-37 {
    flex: 0 0 61.666667%;
    max-width: 61.666667%;
  }
  .col-sm-38 {
    flex: 0 0 63.333333%;
    max-width: 63.333333%;
  }
  .col-sm-39 {
    flex: 0 0 65%;
    max-width: 65%;
  }
  .col-sm-40 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-41 {
    flex: 0 0 68.333333%;
    max-width: 68.333333%;
  }
  .col-sm-42 {
    flex: 0 0 70%;
    max-width: 70%;
  }
  .col-sm-43 {
    flex: 0 0 71.666667%;
    max-width: 71.666667%;
  }
  .col-sm-44 {
    flex: 0 0 73.333333%;
    max-width: 73.333333%;
  }
  .col-sm-45 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-46 {
    flex: 0 0 76.666667%;
    max-width: 76.666667%;
  }
  .col-sm-47 {
    flex: 0 0 78.333333%;
    max-width: 78.333333%;
  }
  .col-sm-48 {
    flex: 0 0 80%;
    max-width: 80%;
  }
  .col-sm-49 {
    flex: 0 0 81.666667%;
    max-width: 81.666667%;
  }
  .col-sm-50 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-51 {
    flex: 0 0 85%;
    max-width: 85%;
  }
  .col-sm-52 {
    flex: 0 0 86.666667%;
    max-width: 86.666667%;
  }
  .col-sm-53 {
    flex: 0 0 88.333333%;
    max-width: 88.333333%;
  }
  .col-sm-54 {
    flex: 0 0 90%;
    max-width: 90%;
  }
  .col-sm-55 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-56 {
    flex: 0 0 93.333333%;
    max-width: 93.333333%;
  }
  .col-sm-57 {
    flex: 0 0 95%;
    max-width: 95%;
  }
  .col-sm-58 {
    flex: 0 0 96.666667%;
    max-width: 96.666667%;
  }
  .col-sm-59 {
    flex: 0 0 98.333333%;
    max-width: 98.333333%;
  }
  .col-sm-60 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 61;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .order-sm-13 {
    order: 13;
  }
  .order-sm-14 {
    order: 14;
  }
  .order-sm-15 {
    order: 15;
  }
  .order-sm-16 {
    order: 16;
  }
  .order-sm-17 {
    order: 17;
  }
  .order-sm-18 {
    order: 18;
  }
  .order-sm-19 {
    order: 19;
  }
  .order-sm-20 {
    order: 20;
  }
  .order-sm-21 {
    order: 21;
  }
  .order-sm-22 {
    order: 22;
  }
  .order-sm-23 {
    order: 23;
  }
  .order-sm-24 {
    order: 24;
  }
  .order-sm-25 {
    order: 25;
  }
  .order-sm-26 {
    order: 26;
  }
  .order-sm-27 {
    order: 27;
  }
  .order-sm-28 {
    order: 28;
  }
  .order-sm-29 {
    order: 29;
  }
  .order-sm-30 {
    order: 30;
  }
  .order-sm-31 {
    order: 31;
  }
  .order-sm-32 {
    order: 32;
  }
  .order-sm-33 {
    order: 33;
  }
  .order-sm-34 {
    order: 34;
  }
  .order-sm-35 {
    order: 35;
  }
  .order-sm-36 {
    order: 36;
  }
  .order-sm-37 {
    order: 37;
  }
  .order-sm-38 {
    order: 38;
  }
  .order-sm-39 {
    order: 39;
  }
  .order-sm-40 {
    order: 40;
  }
  .order-sm-41 {
    order: 41;
  }
  .order-sm-42 {
    order: 42;
  }
  .order-sm-43 {
    order: 43;
  }
  .order-sm-44 {
    order: 44;
  }
  .order-sm-45 {
    order: 45;
  }
  .order-sm-46 {
    order: 46;
  }
  .order-sm-47 {
    order: 47;
  }
  .order-sm-48 {
    order: 48;
  }
  .order-sm-49 {
    order: 49;
  }
  .order-sm-50 {
    order: 50;
  }
  .order-sm-51 {
    order: 51;
  }
  .order-sm-52 {
    order: 52;
  }
  .order-sm-53 {
    order: 53;
  }
  .order-sm-54 {
    order: 54;
  }
  .order-sm-55 {
    order: 55;
  }
  .order-sm-56 {
    order: 56;
  }
  .order-sm-57 {
    order: 57;
  }
  .order-sm-58 {
    order: 58;
  }
  .order-sm-59 {
    order: 59;
  }
  .order-sm-60 {
    order: 60;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 1.666667%;
  }
  .offset-sm-2 {
    margin-left: 3.333333%;
  }
  .offset-sm-3 {
    margin-left: 5%;
  }
  .offset-sm-4 {
    margin-left: 6.666667%;
  }
  .offset-sm-5 {
    margin-left: 8.333333%;
  }
  .offset-sm-6 {
    margin-left: 10%;
  }
  .offset-sm-7 {
    margin-left: 11.666667%;
  }
  .offset-sm-8 {
    margin-left: 13.333333%;
  }
  .offset-sm-9 {
    margin-left: 15%;
  }
  .offset-sm-10 {
    margin-left: 16.666667%;
  }
  .offset-sm-11 {
    margin-left: 18.333333%;
  }
  .offset-sm-12 {
    margin-left: 20%;
  }
  .offset-sm-13 {
    margin-left: 21.666667%;
  }
  .offset-sm-14 {
    margin-left: 23.333333%;
  }
  .offset-sm-15 {
    margin-left: 25%;
  }
  .offset-sm-16 {
    margin-left: 26.666667%;
  }
  .offset-sm-17 {
    margin-left: 28.333333%;
  }
  .offset-sm-18 {
    margin-left: 30%;
  }
  .offset-sm-19 {
    margin-left: 31.666667%;
  }
  .offset-sm-20 {
    margin-left: 33.333333%;
  }
  .offset-sm-21 {
    margin-left: 35%;
  }
  .offset-sm-22 {
    margin-left: 36.666667%;
  }
  .offset-sm-23 {
    margin-left: 38.333333%;
  }
  .offset-sm-24 {
    margin-left: 40%;
  }
  .offset-sm-25 {
    margin-left: 41.666667%;
  }
  .offset-sm-26 {
    margin-left: 43.333333%;
  }
  .offset-sm-27 {
    margin-left: 45%;
  }
  .offset-sm-28 {
    margin-left: 46.666667%;
  }
  .offset-sm-29 {
    margin-left: 48.333333%;
  }
  .offset-sm-30 {
    margin-left: 50%;
  }
  .offset-sm-31 {
    margin-left: 51.666667%;
  }
  .offset-sm-32 {
    margin-left: 53.333333%;
  }
  .offset-sm-33 {
    margin-left: 55%;
  }
  .offset-sm-34 {
    margin-left: 56.666667%;
  }
  .offset-sm-35 {
    margin-left: 58.333333%;
  }
  .offset-sm-36 {
    margin-left: 60%;
  }
  .offset-sm-37 {
    margin-left: 61.666667%;
  }
  .offset-sm-38 {
    margin-left: 63.333333%;
  }
  .offset-sm-39 {
    margin-left: 65%;
  }
  .offset-sm-40 {
    margin-left: 66.666667%;
  }
  .offset-sm-41 {
    margin-left: 68.333333%;
  }
  .offset-sm-42 {
    margin-left: 70%;
  }
  .offset-sm-43 {
    margin-left: 71.666667%;
  }
  .offset-sm-44 {
    margin-left: 73.333333%;
  }
  .offset-sm-45 {
    margin-left: 75%;
  }
  .offset-sm-46 {
    margin-left: 76.666667%;
  }
  .offset-sm-47 {
    margin-left: 78.333333%;
  }
  .offset-sm-48 {
    margin-left: 80%;
  }
  .offset-sm-49 {
    margin-left: 81.666667%;
  }
  .offset-sm-50 {
    margin-left: 83.333333%;
  }
  .offset-sm-51 {
    margin-left: 85%;
  }
  .offset-sm-52 {
    margin-left: 86.666667%;
  }
  .offset-sm-53 {
    margin-left: 88.333333%;
  }
  .offset-sm-54 {
    margin-left: 90%;
  }
  .offset-sm-55 {
    margin-left: 91.666667%;
  }
  .offset-sm-56 {
    margin-left: 93.333333%;
  }
  .offset-sm-57 {
    margin-left: 95%;
  }
  .offset-sm-58 {
    margin-left: 96.666667%;
  }
  .offset-sm-59 {
    margin-left: 98.333333%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    flex: 0 0 1.666667%;
    max-width: 1.666667%;
  }
  .col-md-2 {
    flex: 0 0 3.333333%;
    max-width: 3.333333%;
  }
  .col-md-3 {
    flex: 0 0 5%;
    max-width: 5%;
  }
  .col-md-4 {
    flex: 0 0 6.666667%;
    max-width: 6.666667%;
  }
  .col-md-5 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-6 {
    flex: 0 0 10%;
    max-width: 10%;
  }
  .col-md-7 {
    flex: 0 0 11.666667%;
    max-width: 11.666667%;
  }
  .col-md-8 {
    flex: 0 0 13.333333%;
    max-width: 13.333333%;
  }
  .col-md-9 {
    flex: 0 0 15%;
    max-width: 15%;
  }
  .col-md-10 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-11 {
    flex: 0 0 18.333333%;
    max-width: 18.333333%;
  }
  .col-md-12 {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .col-md-13 {
    flex: 0 0 21.666667%;
    max-width: 21.666667%;
  }
  .col-md-14 {
    flex: 0 0 23.333333%;
    max-width: 23.333333%;
  }
  .col-md-15 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-16 {
    flex: 0 0 26.666667%;
    max-width: 26.666667%;
  }
  .col-md-17 {
    flex: 0 0 28.333333%;
    max-width: 28.333333%;
  }
  .col-md-18 {
    flex: 0 0 30%;
    max-width: 30%;
  }
  .col-md-19 {
    flex: 0 0 31.666667%;
    max-width: 31.666667%;
  }
  .col-md-20 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-21 {
    flex: 0 0 35%;
    max-width: 35%;
  }
  .col-md-22 {
    flex: 0 0 36.666667%;
    max-width: 36.666667%;
  }
  .col-md-23 {
    flex: 0 0 38.333333%;
    max-width: 38.333333%;
  }
  .col-md-24 {
    flex: 0 0 40%;
    max-width: 40%;
  }
  .col-md-25 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-26 {
    flex: 0 0 43.333333%;
    max-width: 43.333333%;
  }
  .col-md-27 {
    flex: 0 0 45%;
    max-width: 45%;
  }
  .col-md-28 {
    flex: 0 0 46.666667%;
    max-width: 46.666667%;
  }
  .col-md-29 {
    flex: 0 0 48.333333%;
    max-width: 48.333333%;
  }
  .col-md-30 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-31 {
    flex: 0 0 51.666667%;
    max-width: 51.666667%;
  }
  .col-md-32 {
    flex: 0 0 53.333333%;
    max-width: 53.333333%;
  }
  .col-md-33 {
    flex: 0 0 55%;
    max-width: 55%;
  }
  .col-md-34 {
    flex: 0 0 56.666667%;
    max-width: 56.666667%;
  }
  .col-md-35 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-36 {
    flex: 0 0 60%;
    max-width: 60%;
  }
  .col-md-37 {
    flex: 0 0 61.666667%;
    max-width: 61.666667%;
  }
  .col-md-38 {
    flex: 0 0 63.333333%;
    max-width: 63.333333%;
  }
  .col-md-39 {
    flex: 0 0 65%;
    max-width: 65%;
  }
  .col-md-40 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-41 {
    flex: 0 0 68.333333%;
    max-width: 68.333333%;
  }
  .col-md-42 {
    flex: 0 0 70%;
    max-width: 70%;
  }
  .col-md-43 {
    flex: 0 0 71.666667%;
    max-width: 71.666667%;
  }
  .col-md-44 {
    flex: 0 0 73.333333%;
    max-width: 73.333333%;
  }
  .col-md-45 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-46 {
    flex: 0 0 76.666667%;
    max-width: 76.666667%;
  }
  .col-md-47 {
    flex: 0 0 78.333333%;
    max-width: 78.333333%;
  }
  .col-md-48 {
    flex: 0 0 80%;
    max-width: 80%;
  }
  .col-md-49 {
    flex: 0 0 81.666667%;
    max-width: 81.666667%;
  }
  .col-md-50 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-51 {
    flex: 0 0 85%;
    max-width: 85%;
  }
  .col-md-52 {
    flex: 0 0 86.666667%;
    max-width: 86.666667%;
  }
  .col-md-53 {
    flex: 0 0 88.333333%;
    max-width: 88.333333%;
  }
  .col-md-54 {
    flex: 0 0 90%;
    max-width: 90%;
  }
  .col-md-55 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-56 {
    flex: 0 0 93.333333%;
    max-width: 93.333333%;
  }
  .col-md-57 {
    flex: 0 0 95%;
    max-width: 95%;
  }
  .col-md-58 {
    flex: 0 0 96.666667%;
    max-width: 96.666667%;
  }
  .col-md-59 {
    flex: 0 0 98.333333%;
    max-width: 98.333333%;
  }
  .col-md-60 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 61;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .order-md-13 {
    order: 13;
  }
  .order-md-14 {
    order: 14;
  }
  .order-md-15 {
    order: 15;
  }
  .order-md-16 {
    order: 16;
  }
  .order-md-17 {
    order: 17;
  }
  .order-md-18 {
    order: 18;
  }
  .order-md-19 {
    order: 19;
  }
  .order-md-20 {
    order: 20;
  }
  .order-md-21 {
    order: 21;
  }
  .order-md-22 {
    order: 22;
  }
  .order-md-23 {
    order: 23;
  }
  .order-md-24 {
    order: 24;
  }
  .order-md-25 {
    order: 25;
  }
  .order-md-26 {
    order: 26;
  }
  .order-md-27 {
    order: 27;
  }
  .order-md-28 {
    order: 28;
  }
  .order-md-29 {
    order: 29;
  }
  .order-md-30 {
    order: 30;
  }
  .order-md-31 {
    order: 31;
  }
  .order-md-32 {
    order: 32;
  }
  .order-md-33 {
    order: 33;
  }
  .order-md-34 {
    order: 34;
  }
  .order-md-35 {
    order: 35;
  }
  .order-md-36 {
    order: 36;
  }
  .order-md-37 {
    order: 37;
  }
  .order-md-38 {
    order: 38;
  }
  .order-md-39 {
    order: 39;
  }
  .order-md-40 {
    order: 40;
  }
  .order-md-41 {
    order: 41;
  }
  .order-md-42 {
    order: 42;
  }
  .order-md-43 {
    order: 43;
  }
  .order-md-44 {
    order: 44;
  }
  .order-md-45 {
    order: 45;
  }
  .order-md-46 {
    order: 46;
  }
  .order-md-47 {
    order: 47;
  }
  .order-md-48 {
    order: 48;
  }
  .order-md-49 {
    order: 49;
  }
  .order-md-50 {
    order: 50;
  }
  .order-md-51 {
    order: 51;
  }
  .order-md-52 {
    order: 52;
  }
  .order-md-53 {
    order: 53;
  }
  .order-md-54 {
    order: 54;
  }
  .order-md-55 {
    order: 55;
  }
  .order-md-56 {
    order: 56;
  }
  .order-md-57 {
    order: 57;
  }
  .order-md-58 {
    order: 58;
  }
  .order-md-59 {
    order: 59;
  }
  .order-md-60 {
    order: 60;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 1.666667%;
  }
  .offset-md-2 {
    margin-left: 3.333333%;
  }
  .offset-md-3 {
    margin-left: 5%;
  }
  .offset-md-4 {
    margin-left: 6.666667%;
  }
  .offset-md-5 {
    margin-left: 8.333333%;
  }
  .offset-md-6 {
    margin-left: 10%;
  }
  .offset-md-7 {
    margin-left: 11.666667%;
  }
  .offset-md-8 {
    margin-left: 13.333333%;
  }
  .offset-md-9 {
    margin-left: 15%;
  }
  .offset-md-10 {
    margin-left: 16.666667%;
  }
  .offset-md-11 {
    margin-left: 18.333333%;
  }
  .offset-md-12 {
    margin-left: 20%;
  }
  .offset-md-13 {
    margin-left: 21.666667%;
  }
  .offset-md-14 {
    margin-left: 23.333333%;
  }
  .offset-md-15 {
    margin-left: 25%;
  }
  .offset-md-16 {
    margin-left: 26.666667%;
  }
  .offset-md-17 {
    margin-left: 28.333333%;
  }
  .offset-md-18 {
    margin-left: 30%;
  }
  .offset-md-19 {
    margin-left: 31.666667%;
  }
  .offset-md-20 {
    margin-left: 33.333333%;
  }
  .offset-md-21 {
    margin-left: 35%;
  }
  .offset-md-22 {
    margin-left: 36.666667%;
  }
  .offset-md-23 {
    margin-left: 38.333333%;
  }
  .offset-md-24 {
    margin-left: 40%;
  }
  .offset-md-25 {
    margin-left: 41.666667%;
  }
  .offset-md-26 {
    margin-left: 43.333333%;
  }
  .offset-md-27 {
    margin-left: 45%;
  }
  .offset-md-28 {
    margin-left: 46.666667%;
  }
  .offset-md-29 {
    margin-left: 48.333333%;
  }
  .offset-md-30 {
    margin-left: 50%;
  }
  .offset-md-31 {
    margin-left: 51.666667%;
  }
  .offset-md-32 {
    margin-left: 53.333333%;
  }
  .offset-md-33 {
    margin-left: 55%;
  }
  .offset-md-34 {
    margin-left: 56.666667%;
  }
  .offset-md-35 {
    margin-left: 58.333333%;
  }
  .offset-md-36 {
    margin-left: 60%;
  }
  .offset-md-37 {
    margin-left: 61.666667%;
  }
  .offset-md-38 {
    margin-left: 63.333333%;
  }
  .offset-md-39 {
    margin-left: 65%;
  }
  .offset-md-40 {
    margin-left: 66.666667%;
  }
  .offset-md-41 {
    margin-left: 68.333333%;
  }
  .offset-md-42 {
    margin-left: 70%;
  }
  .offset-md-43 {
    margin-left: 71.666667%;
  }
  .offset-md-44 {
    margin-left: 73.333333%;
  }
  .offset-md-45 {
    margin-left: 75%;
  }
  .offset-md-46 {
    margin-left: 76.666667%;
  }
  .offset-md-47 {
    margin-left: 78.333333%;
  }
  .offset-md-48 {
    margin-left: 80%;
  }
  .offset-md-49 {
    margin-left: 81.666667%;
  }
  .offset-md-50 {
    margin-left: 83.333333%;
  }
  .offset-md-51 {
    margin-left: 85%;
  }
  .offset-md-52 {
    margin-left: 86.666667%;
  }
  .offset-md-53 {
    margin-left: 88.333333%;
  }
  .offset-md-54 {
    margin-left: 90%;
  }
  .offset-md-55 {
    margin-left: 91.666667%;
  }
  .offset-md-56 {
    margin-left: 93.333333%;
  }
  .offset-md-57 {
    margin-left: 95%;
  }
  .offset-md-58 {
    margin-left: 96.666667%;
  }
  .offset-md-59 {
    margin-left: 98.333333%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    flex: 0 0 1.666667%;
    max-width: 1.666667%;
  }
  .col-lg-2 {
    flex: 0 0 3.333333%;
    max-width: 3.333333%;
  }
  .col-lg-3 {
    flex: 0 0 5%;
    max-width: 5%;
  }
  .col-lg-4 {
    flex: 0 0 6.666667%;
    max-width: 6.666667%;
  }
  .col-lg-5 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-6 {
    flex: 0 0 10%;
    max-width: 10%;
  }
  .col-lg-7 {
    flex: 0 0 11.666667%;
    max-width: 11.666667%;
  }
  .col-lg-8 {
    flex: 0 0 13.333333%;
    max-width: 13.333333%;
  }
  .col-lg-9 {
    flex: 0 0 15%;
    max-width: 15%;
  }
  .col-lg-10 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-11 {
    flex: 0 0 18.333333%;
    max-width: 18.333333%;
  }
  .col-lg-12 {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .col-lg-13 {
    flex: 0 0 21.666667%;
    max-width: 21.666667%;
  }
  .col-lg-14 {
    flex: 0 0 23.333333%;
    max-width: 23.333333%;
  }
  .col-lg-15 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-16 {
    flex: 0 0 26.666667%;
    max-width: 26.666667%;
  }
  .col-lg-17 {
    flex: 0 0 28.333333%;
    max-width: 28.333333%;
  }
  .col-lg-18 {
    flex: 0 0 30%;
    max-width: 30%;
  }
  .col-lg-19 {
    flex: 0 0 31.666667%;
    max-width: 31.666667%;
  }
  .col-lg-20 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-21 {
    flex: 0 0 35%;
    max-width: 35%;
  }
  .col-lg-22 {
    flex: 0 0 36.666667%;
    max-width: 36.666667%;
  }
  .col-lg-23 {
    flex: 0 0 38.333333%;
    max-width: 38.333333%;
  }
  .col-lg-24 {
    flex: 0 0 40%;
    max-width: 40%;
  }
  .col-lg-25 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-26 {
    flex: 0 0 43.333333%;
    max-width: 43.333333%;
  }
  .col-lg-27 {
    flex: 0 0 45%;
    max-width: 45%;
  }
  .col-lg-28 {
    flex: 0 0 46.666667%;
    max-width: 46.666667%;
  }
  .col-lg-29 {
    flex: 0 0 48.333333%;
    max-width: 48.333333%;
  }
  .col-lg-30 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-31 {
    flex: 0 0 51.666667%;
    max-width: 51.666667%;
  }
  .col-lg-32 {
    flex: 0 0 53.333333%;
    max-width: 53.333333%;
  }
  .col-lg-33 {
    flex: 0 0 55%;
    max-width: 55%;
  }
  .col-lg-34 {
    flex: 0 0 56.666667%;
    max-width: 56.666667%;
  }
  .col-lg-35 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-36 {
    flex: 0 0 60%;
    max-width: 60%;
  }
  .col-lg-37 {
    flex: 0 0 61.666667%;
    max-width: 61.666667%;
  }
  .col-lg-38 {
    flex: 0 0 63.333333%;
    max-width: 63.333333%;
  }
  .col-lg-39 {
    flex: 0 0 65%;
    max-width: 65%;
  }
  .col-lg-40 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-41 {
    flex: 0 0 68.333333%;
    max-width: 68.333333%;
  }
  .col-lg-42 {
    flex: 0 0 70%;
    max-width: 70%;
  }
  .col-lg-43 {
    flex: 0 0 71.666667%;
    max-width: 71.666667%;
  }
  .col-lg-44 {
    flex: 0 0 73.333333%;
    max-width: 73.333333%;
  }
  .col-lg-45 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-46 {
    flex: 0 0 76.666667%;
    max-width: 76.666667%;
  }
  .col-lg-47 {
    flex: 0 0 78.333333%;
    max-width: 78.333333%;
  }
  .col-lg-48 {
    flex: 0 0 80%;
    max-width: 80%;
  }
  .col-lg-49 {
    flex: 0 0 81.666667%;
    max-width: 81.666667%;
  }
  .col-lg-50 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-51 {
    flex: 0 0 85%;
    max-width: 85%;
  }
  .col-lg-52 {
    flex: 0 0 86.666667%;
    max-width: 86.666667%;
  }
  .col-lg-53 {
    flex: 0 0 88.333333%;
    max-width: 88.333333%;
  }
  .col-lg-54 {
    flex: 0 0 90%;
    max-width: 90%;
  }
  .col-lg-55 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-56 {
    flex: 0 0 93.333333%;
    max-width: 93.333333%;
  }
  .col-lg-57 {
    flex: 0 0 95%;
    max-width: 95%;
  }
  .col-lg-58 {
    flex: 0 0 96.666667%;
    max-width: 96.666667%;
  }
  .col-lg-59 {
    flex: 0 0 98.333333%;
    max-width: 98.333333%;
  }
  .col-lg-60 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 61;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .order-lg-13 {
    order: 13;
  }
  .order-lg-14 {
    order: 14;
  }
  .order-lg-15 {
    order: 15;
  }
  .order-lg-16 {
    order: 16;
  }
  .order-lg-17 {
    order: 17;
  }
  .order-lg-18 {
    order: 18;
  }
  .order-lg-19 {
    order: 19;
  }
  .order-lg-20 {
    order: 20;
  }
  .order-lg-21 {
    order: 21;
  }
  .order-lg-22 {
    order: 22;
  }
  .order-lg-23 {
    order: 23;
  }
  .order-lg-24 {
    order: 24;
  }
  .order-lg-25 {
    order: 25;
  }
  .order-lg-26 {
    order: 26;
  }
  .order-lg-27 {
    order: 27;
  }
  .order-lg-28 {
    order: 28;
  }
  .order-lg-29 {
    order: 29;
  }
  .order-lg-30 {
    order: 30;
  }
  .order-lg-31 {
    order: 31;
  }
  .order-lg-32 {
    order: 32;
  }
  .order-lg-33 {
    order: 33;
  }
  .order-lg-34 {
    order: 34;
  }
  .order-lg-35 {
    order: 35;
  }
  .order-lg-36 {
    order: 36;
  }
  .order-lg-37 {
    order: 37;
  }
  .order-lg-38 {
    order: 38;
  }
  .order-lg-39 {
    order: 39;
  }
  .order-lg-40 {
    order: 40;
  }
  .order-lg-41 {
    order: 41;
  }
  .order-lg-42 {
    order: 42;
  }
  .order-lg-43 {
    order: 43;
  }
  .order-lg-44 {
    order: 44;
  }
  .order-lg-45 {
    order: 45;
  }
  .order-lg-46 {
    order: 46;
  }
  .order-lg-47 {
    order: 47;
  }
  .order-lg-48 {
    order: 48;
  }
  .order-lg-49 {
    order: 49;
  }
  .order-lg-50 {
    order: 50;
  }
  .order-lg-51 {
    order: 51;
  }
  .order-lg-52 {
    order: 52;
  }
  .order-lg-53 {
    order: 53;
  }
  .order-lg-54 {
    order: 54;
  }
  .order-lg-55 {
    order: 55;
  }
  .order-lg-56 {
    order: 56;
  }
  .order-lg-57 {
    order: 57;
  }
  .order-lg-58 {
    order: 58;
  }
  .order-lg-59 {
    order: 59;
  }
  .order-lg-60 {
    order: 60;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 1.666667%;
  }
  .offset-lg-2 {
    margin-left: 3.333333%;
  }
  .offset-lg-3 {
    margin-left: 5%;
  }
  .offset-lg-4 {
    margin-left: 6.666667%;
  }
  .offset-lg-5 {
    margin-left: 8.333333%;
  }
  .offset-lg-6 {
    margin-left: 10%;
  }
  .offset-lg-7 {
    margin-left: 11.666667%;
  }
  .offset-lg-8 {
    margin-left: 13.333333%;
  }
  .offset-lg-9 {
    margin-left: 15%;
  }
  .offset-lg-10 {
    margin-left: 16.666667%;
  }
  .offset-lg-11 {
    margin-left: 18.333333%;
  }
  .offset-lg-12 {
    margin-left: 20%;
  }
  .offset-lg-13 {
    margin-left: 21.666667%;
  }
  .offset-lg-14 {
    margin-left: 23.333333%;
  }
  .offset-lg-15 {
    margin-left: 25%;
  }
  .offset-lg-16 {
    margin-left: 26.666667%;
  }
  .offset-lg-17 {
    margin-left: 28.333333%;
  }
  .offset-lg-18 {
    margin-left: 30%;
  }
  .offset-lg-19 {
    margin-left: 31.666667%;
  }
  .offset-lg-20 {
    margin-left: 33.333333%;
  }
  .offset-lg-21 {
    margin-left: 35%;
  }
  .offset-lg-22 {
    margin-left: 36.666667%;
  }
  .offset-lg-23 {
    margin-left: 38.333333%;
  }
  .offset-lg-24 {
    margin-left: 40%;
  }
  .offset-lg-25 {
    margin-left: 41.666667%;
  }
  .offset-lg-26 {
    margin-left: 43.333333%;
  }
  .offset-lg-27 {
    margin-left: 45%;
  }
  .offset-lg-28 {
    margin-left: 46.666667%;
  }
  .offset-lg-29 {
    margin-left: 48.333333%;
  }
  .offset-lg-30 {
    margin-left: 50%;
  }
  .offset-lg-31 {
    margin-left: 51.666667%;
  }
  .offset-lg-32 {
    margin-left: 53.333333%;
  }
  .offset-lg-33 {
    margin-left: 55%;
  }
  .offset-lg-34 {
    margin-left: 56.666667%;
  }
  .offset-lg-35 {
    margin-left: 58.333333%;
  }
  .offset-lg-36 {
    margin-left: 60%;
  }
  .offset-lg-37 {
    margin-left: 61.666667%;
  }
  .offset-lg-38 {
    margin-left: 63.333333%;
  }
  .offset-lg-39 {
    margin-left: 65%;
  }
  .offset-lg-40 {
    margin-left: 66.666667%;
  }
  .offset-lg-41 {
    margin-left: 68.333333%;
  }
  .offset-lg-42 {
    margin-left: 70%;
  }
  .offset-lg-43 {
    margin-left: 71.666667%;
  }
  .offset-lg-44 {
    margin-left: 73.333333%;
  }
  .offset-lg-45 {
    margin-left: 75%;
  }
  .offset-lg-46 {
    margin-left: 76.666667%;
  }
  .offset-lg-47 {
    margin-left: 78.333333%;
  }
  .offset-lg-48 {
    margin-left: 80%;
  }
  .offset-lg-49 {
    margin-left: 81.666667%;
  }
  .offset-lg-50 {
    margin-left: 83.333333%;
  }
  .offset-lg-51 {
    margin-left: 85%;
  }
  .offset-lg-52 {
    margin-left: 86.666667%;
  }
  .offset-lg-53 {
    margin-left: 88.333333%;
  }
  .offset-lg-54 {
    margin-left: 90%;
  }
  .offset-lg-55 {
    margin-left: 91.666667%;
  }
  .offset-lg-56 {
    margin-left: 93.333333%;
  }
  .offset-lg-57 {
    margin-left: 95%;
  }
  .offset-lg-58 {
    margin-left: 96.666667%;
  }
  .offset-lg-59 {
    margin-left: 98.333333%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    flex: 0 0 1.666667%;
    max-width: 1.666667%;
  }
  .col-xl-2 {
    flex: 0 0 3.333333%;
    max-width: 3.333333%;
  }
  .col-xl-3 {
    flex: 0 0 5%;
    max-width: 5%;
  }
  .col-xl-4 {
    flex: 0 0 6.666667%;
    max-width: 6.666667%;
  }
  .col-xl-5 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-6 {
    flex: 0 0 10%;
    max-width: 10%;
  }
  .col-xl-7 {
    flex: 0 0 11.666667%;
    max-width: 11.666667%;
  }
  .col-xl-8 {
    flex: 0 0 13.333333%;
    max-width: 13.333333%;
  }
  .col-xl-9 {
    flex: 0 0 15%;
    max-width: 15%;
  }
  .col-xl-10 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-11 {
    flex: 0 0 18.333333%;
    max-width: 18.333333%;
  }
  .col-xl-12 {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .col-xl-13 {
    flex: 0 0 21.666667%;
    max-width: 21.666667%;
  }
  .col-xl-14 {
    flex: 0 0 23.333333%;
    max-width: 23.333333%;
  }
  .col-xl-15 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-16 {
    flex: 0 0 26.666667%;
    max-width: 26.666667%;
  }
  .col-xl-17 {
    flex: 0 0 28.333333%;
    max-width: 28.333333%;
  }
  .col-xl-18 {
    flex: 0 0 30%;
    max-width: 30%;
  }
  .col-xl-19 {
    flex: 0 0 31.666667%;
    max-width: 31.666667%;
  }
  .col-xl-20 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-21 {
    flex: 0 0 35%;
    max-width: 35%;
  }
  .col-xl-22 {
    flex: 0 0 36.666667%;
    max-width: 36.666667%;
  }
  .col-xl-23 {
    flex: 0 0 38.333333%;
    max-width: 38.333333%;
  }
  .col-xl-24 {
    flex: 0 0 40%;
    max-width: 40%;
  }
  .col-xl-25 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-26 {
    flex: 0 0 43.333333%;
    max-width: 43.333333%;
  }
  .col-xl-27 {
    flex: 0 0 45%;
    max-width: 45%;
  }
  .col-xl-28 {
    flex: 0 0 46.666667%;
    max-width: 46.666667%;
  }
  .col-xl-29 {
    flex: 0 0 48.333333%;
    max-width: 48.333333%;
  }
  .col-xl-30 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-31 {
    flex: 0 0 51.666667%;
    max-width: 51.666667%;
  }
  .col-xl-32 {
    flex: 0 0 53.333333%;
    max-width: 53.333333%;
  }
  .col-xl-33 {
    flex: 0 0 55%;
    max-width: 55%;
  }
  .col-xl-34 {
    flex: 0 0 56.666667%;
    max-width: 56.666667%;
  }
  .col-xl-35 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-36 {
    flex: 0 0 60%;
    max-width: 60%;
  }
  .col-xl-37 {
    flex: 0 0 61.666667%;
    max-width: 61.666667%;
  }
  .col-xl-38 {
    flex: 0 0 63.333333%;
    max-width: 63.333333%;
  }
  .col-xl-39 {
    flex: 0 0 65%;
    max-width: 65%;
  }
  .col-xl-40 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-41 {
    flex: 0 0 68.333333%;
    max-width: 68.333333%;
  }
  .col-xl-42 {
    flex: 0 0 70%;
    max-width: 70%;
  }
  .col-xl-43 {
    flex: 0 0 71.666667%;
    max-width: 71.666667%;
  }
  .col-xl-44 {
    flex: 0 0 73.333333%;
    max-width: 73.333333%;
  }
  .col-xl-45 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-46 {
    flex: 0 0 76.666667%;
    max-width: 76.666667%;
  }
  .col-xl-47 {
    flex: 0 0 78.333333%;
    max-width: 78.333333%;
  }
  .col-xl-48 {
    flex: 0 0 80%;
    max-width: 80%;
  }
  .col-xl-49 {
    flex: 0 0 81.666667%;
    max-width: 81.666667%;
  }
  .col-xl-50 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-51 {
    flex: 0 0 85%;
    max-width: 85%;
  }
  .col-xl-52 {
    flex: 0 0 86.666667%;
    max-width: 86.666667%;
  }
  .col-xl-53 {
    flex: 0 0 88.333333%;
    max-width: 88.333333%;
  }
  .col-xl-54 {
    flex: 0 0 90%;
    max-width: 90%;
  }
  .col-xl-55 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-56 {
    flex: 0 0 93.333333%;
    max-width: 93.333333%;
  }
  .col-xl-57 {
    flex: 0 0 95%;
    max-width: 95%;
  }
  .col-xl-58 {
    flex: 0 0 96.666667%;
    max-width: 96.666667%;
  }
  .col-xl-59 {
    flex: 0 0 98.333333%;
    max-width: 98.333333%;
  }
  .col-xl-60 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 61;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .order-xl-13 {
    order: 13;
  }
  .order-xl-14 {
    order: 14;
  }
  .order-xl-15 {
    order: 15;
  }
  .order-xl-16 {
    order: 16;
  }
  .order-xl-17 {
    order: 17;
  }
  .order-xl-18 {
    order: 18;
  }
  .order-xl-19 {
    order: 19;
  }
  .order-xl-20 {
    order: 20;
  }
  .order-xl-21 {
    order: 21;
  }
  .order-xl-22 {
    order: 22;
  }
  .order-xl-23 {
    order: 23;
  }
  .order-xl-24 {
    order: 24;
  }
  .order-xl-25 {
    order: 25;
  }
  .order-xl-26 {
    order: 26;
  }
  .order-xl-27 {
    order: 27;
  }
  .order-xl-28 {
    order: 28;
  }
  .order-xl-29 {
    order: 29;
  }
  .order-xl-30 {
    order: 30;
  }
  .order-xl-31 {
    order: 31;
  }
  .order-xl-32 {
    order: 32;
  }
  .order-xl-33 {
    order: 33;
  }
  .order-xl-34 {
    order: 34;
  }
  .order-xl-35 {
    order: 35;
  }
  .order-xl-36 {
    order: 36;
  }
  .order-xl-37 {
    order: 37;
  }
  .order-xl-38 {
    order: 38;
  }
  .order-xl-39 {
    order: 39;
  }
  .order-xl-40 {
    order: 40;
  }
  .order-xl-41 {
    order: 41;
  }
  .order-xl-42 {
    order: 42;
  }
  .order-xl-43 {
    order: 43;
  }
  .order-xl-44 {
    order: 44;
  }
  .order-xl-45 {
    order: 45;
  }
  .order-xl-46 {
    order: 46;
  }
  .order-xl-47 {
    order: 47;
  }
  .order-xl-48 {
    order: 48;
  }
  .order-xl-49 {
    order: 49;
  }
  .order-xl-50 {
    order: 50;
  }
  .order-xl-51 {
    order: 51;
  }
  .order-xl-52 {
    order: 52;
  }
  .order-xl-53 {
    order: 53;
  }
  .order-xl-54 {
    order: 54;
  }
  .order-xl-55 {
    order: 55;
  }
  .order-xl-56 {
    order: 56;
  }
  .order-xl-57 {
    order: 57;
  }
  .order-xl-58 {
    order: 58;
  }
  .order-xl-59 {
    order: 59;
  }
  .order-xl-60 {
    order: 60;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 1.666667%;
  }
  .offset-xl-2 {
    margin-left: 3.333333%;
  }
  .offset-xl-3 {
    margin-left: 5%;
  }
  .offset-xl-4 {
    margin-left: 6.666667%;
  }
  .offset-xl-5 {
    margin-left: 8.333333%;
  }
  .offset-xl-6 {
    margin-left: 10%;
  }
  .offset-xl-7 {
    margin-left: 11.666667%;
  }
  .offset-xl-8 {
    margin-left: 13.333333%;
  }
  .offset-xl-9 {
    margin-left: 15%;
  }
  .offset-xl-10 {
    margin-left: 16.666667%;
  }
  .offset-xl-11 {
    margin-left: 18.333333%;
  }
  .offset-xl-12 {
    margin-left: 20%;
  }
  .offset-xl-13 {
    margin-left: 21.666667%;
  }
  .offset-xl-14 {
    margin-left: 23.333333%;
  }
  .offset-xl-15 {
    margin-left: 25%;
  }
  .offset-xl-16 {
    margin-left: 26.666667%;
  }
  .offset-xl-17 {
    margin-left: 28.333333%;
  }
  .offset-xl-18 {
    margin-left: 30%;
  }
  .offset-xl-19 {
    margin-left: 31.666667%;
  }
  .offset-xl-20 {
    margin-left: 33.333333%;
  }
  .offset-xl-21 {
    margin-left: 35%;
  }
  .offset-xl-22 {
    margin-left: 36.666667%;
  }
  .offset-xl-23 {
    margin-left: 38.333333%;
  }
  .offset-xl-24 {
    margin-left: 40%;
  }
  .offset-xl-25 {
    margin-left: 41.666667%;
  }
  .offset-xl-26 {
    margin-left: 43.333333%;
  }
  .offset-xl-27 {
    margin-left: 45%;
  }
  .offset-xl-28 {
    margin-left: 46.666667%;
  }
  .offset-xl-29 {
    margin-left: 48.333333%;
  }
  .offset-xl-30 {
    margin-left: 50%;
  }
  .offset-xl-31 {
    margin-left: 51.666667%;
  }
  .offset-xl-32 {
    margin-left: 53.333333%;
  }
  .offset-xl-33 {
    margin-left: 55%;
  }
  .offset-xl-34 {
    margin-left: 56.666667%;
  }
  .offset-xl-35 {
    margin-left: 58.333333%;
  }
  .offset-xl-36 {
    margin-left: 60%;
  }
  .offset-xl-37 {
    margin-left: 61.666667%;
  }
  .offset-xl-38 {
    margin-left: 63.333333%;
  }
  .offset-xl-39 {
    margin-left: 65%;
  }
  .offset-xl-40 {
    margin-left: 66.666667%;
  }
  .offset-xl-41 {
    margin-left: 68.333333%;
  }
  .offset-xl-42 {
    margin-left: 70%;
  }
  .offset-xl-43 {
    margin-left: 71.666667%;
  }
  .offset-xl-44 {
    margin-left: 73.333333%;
  }
  .offset-xl-45 {
    margin-left: 75%;
  }
  .offset-xl-46 {
    margin-left: 76.666667%;
  }
  .offset-xl-47 {
    margin-left: 78.333333%;
  }
  .offset-xl-48 {
    margin-left: 80%;
  }
  .offset-xl-49 {
    margin-left: 81.666667%;
  }
  .offset-xl-50 {
    margin-left: 83.333333%;
  }
  .offset-xl-51 {
    margin-left: 85%;
  }
  .offset-xl-52 {
    margin-left: 86.666667%;
  }
  .offset-xl-53 {
    margin-left: 88.333333%;
  }
  .offset-xl-54 {
    margin-left: 90%;
  }
  .offset-xl-55 {
    margin-left: 91.666667%;
  }
  .offset-xl-56 {
    margin-left: 93.333333%;
  }
  .offset-xl-57 {
    margin-left: 95%;
  }
  .offset-xl-58 {
    margin-left: 96.666667%;
  }
  .offset-xl-59 {
    margin-left: 98.333333%;
  }
}

@media (min-width: 1600px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xxl-1 {
    flex: 0 0 1.666667%;
    max-width: 1.666667%;
  }
  .col-xxl-2 {
    flex: 0 0 3.333333%;
    max-width: 3.333333%;
  }
  .col-xxl-3 {
    flex: 0 0 5%;
    max-width: 5%;
  }
  .col-xxl-4 {
    flex: 0 0 6.666667%;
    max-width: 6.666667%;
  }
  .col-xxl-5 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xxl-6 {
    flex: 0 0 10%;
    max-width: 10%;
  }
  .col-xxl-7 {
    flex: 0 0 11.666667%;
    max-width: 11.666667%;
  }
  .col-xxl-8 {
    flex: 0 0 13.333333%;
    max-width: 13.333333%;
  }
  .col-xxl-9 {
    flex: 0 0 15%;
    max-width: 15%;
  }
  .col-xxl-10 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xxl-11 {
    flex: 0 0 18.333333%;
    max-width: 18.333333%;
  }
  .col-xxl-12 {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .col-xxl-13 {
    flex: 0 0 21.666667%;
    max-width: 21.666667%;
  }
  .col-xxl-14 {
    flex: 0 0 23.333333%;
    max-width: 23.333333%;
  }
  .col-xxl-15 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-16 {
    flex: 0 0 26.666667%;
    max-width: 26.666667%;
  }
  .col-xxl-17 {
    flex: 0 0 28.333333%;
    max-width: 28.333333%;
  }
  .col-xxl-18 {
    flex: 0 0 30%;
    max-width: 30%;
  }
  .col-xxl-19 {
    flex: 0 0 31.666667%;
    max-width: 31.666667%;
  }
  .col-xxl-20 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xxl-21 {
    flex: 0 0 35%;
    max-width: 35%;
  }
  .col-xxl-22 {
    flex: 0 0 36.666667%;
    max-width: 36.666667%;
  }
  .col-xxl-23 {
    flex: 0 0 38.333333%;
    max-width: 38.333333%;
  }
  .col-xxl-24 {
    flex: 0 0 40%;
    max-width: 40%;
  }
  .col-xxl-25 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xxl-26 {
    flex: 0 0 43.333333%;
    max-width: 43.333333%;
  }
  .col-xxl-27 {
    flex: 0 0 45%;
    max-width: 45%;
  }
  .col-xxl-28 {
    flex: 0 0 46.666667%;
    max-width: 46.666667%;
  }
  .col-xxl-29 {
    flex: 0 0 48.333333%;
    max-width: 48.333333%;
  }
  .col-xxl-30 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-31 {
    flex: 0 0 51.666667%;
    max-width: 51.666667%;
  }
  .col-xxl-32 {
    flex: 0 0 53.333333%;
    max-width: 53.333333%;
  }
  .col-xxl-33 {
    flex: 0 0 55%;
    max-width: 55%;
  }
  .col-xxl-34 {
    flex: 0 0 56.666667%;
    max-width: 56.666667%;
  }
  .col-xxl-35 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xxl-36 {
    flex: 0 0 60%;
    max-width: 60%;
  }
  .col-xxl-37 {
    flex: 0 0 61.666667%;
    max-width: 61.666667%;
  }
  .col-xxl-38 {
    flex: 0 0 63.333333%;
    max-width: 63.333333%;
  }
  .col-xxl-39 {
    flex: 0 0 65%;
    max-width: 65%;
  }
  .col-xxl-40 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xxl-41 {
    flex: 0 0 68.333333%;
    max-width: 68.333333%;
  }
  .col-xxl-42 {
    flex: 0 0 70%;
    max-width: 70%;
  }
  .col-xxl-43 {
    flex: 0 0 71.666667%;
    max-width: 71.666667%;
  }
  .col-xxl-44 {
    flex: 0 0 73.333333%;
    max-width: 73.333333%;
  }
  .col-xxl-45 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-46 {
    flex: 0 0 76.666667%;
    max-width: 76.666667%;
  }
  .col-xxl-47 {
    flex: 0 0 78.333333%;
    max-width: 78.333333%;
  }
  .col-xxl-48 {
    flex: 0 0 80%;
    max-width: 80%;
  }
  .col-xxl-49 {
    flex: 0 0 81.666667%;
    max-width: 81.666667%;
  }
  .col-xxl-50 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xxl-51 {
    flex: 0 0 85%;
    max-width: 85%;
  }
  .col-xxl-52 {
    flex: 0 0 86.666667%;
    max-width: 86.666667%;
  }
  .col-xxl-53 {
    flex: 0 0 88.333333%;
    max-width: 88.333333%;
  }
  .col-xxl-54 {
    flex: 0 0 90%;
    max-width: 90%;
  }
  .col-xxl-55 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xxl-56 {
    flex: 0 0 93.333333%;
    max-width: 93.333333%;
  }
  .col-xxl-57 {
    flex: 0 0 95%;
    max-width: 95%;
  }
  .col-xxl-58 {
    flex: 0 0 96.666667%;
    max-width: 96.666667%;
  }
  .col-xxl-59 {
    flex: 0 0 98.333333%;
    max-width: 98.333333%;
  }
  .col-xxl-60 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xxl-first {
    order: -1;
  }
  .order-xxl-last {
    order: 61;
  }
  .order-xxl-0 {
    order: 0;
  }
  .order-xxl-1 {
    order: 1;
  }
  .order-xxl-2 {
    order: 2;
  }
  .order-xxl-3 {
    order: 3;
  }
  .order-xxl-4 {
    order: 4;
  }
  .order-xxl-5 {
    order: 5;
  }
  .order-xxl-6 {
    order: 6;
  }
  .order-xxl-7 {
    order: 7;
  }
  .order-xxl-8 {
    order: 8;
  }
  .order-xxl-9 {
    order: 9;
  }
  .order-xxl-10 {
    order: 10;
  }
  .order-xxl-11 {
    order: 11;
  }
  .order-xxl-12 {
    order: 12;
  }
  .order-xxl-13 {
    order: 13;
  }
  .order-xxl-14 {
    order: 14;
  }
  .order-xxl-15 {
    order: 15;
  }
  .order-xxl-16 {
    order: 16;
  }
  .order-xxl-17 {
    order: 17;
  }
  .order-xxl-18 {
    order: 18;
  }
  .order-xxl-19 {
    order: 19;
  }
  .order-xxl-20 {
    order: 20;
  }
  .order-xxl-21 {
    order: 21;
  }
  .order-xxl-22 {
    order: 22;
  }
  .order-xxl-23 {
    order: 23;
  }
  .order-xxl-24 {
    order: 24;
  }
  .order-xxl-25 {
    order: 25;
  }
  .order-xxl-26 {
    order: 26;
  }
  .order-xxl-27 {
    order: 27;
  }
  .order-xxl-28 {
    order: 28;
  }
  .order-xxl-29 {
    order: 29;
  }
  .order-xxl-30 {
    order: 30;
  }
  .order-xxl-31 {
    order: 31;
  }
  .order-xxl-32 {
    order: 32;
  }
  .order-xxl-33 {
    order: 33;
  }
  .order-xxl-34 {
    order: 34;
  }
  .order-xxl-35 {
    order: 35;
  }
  .order-xxl-36 {
    order: 36;
  }
  .order-xxl-37 {
    order: 37;
  }
  .order-xxl-38 {
    order: 38;
  }
  .order-xxl-39 {
    order: 39;
  }
  .order-xxl-40 {
    order: 40;
  }
  .order-xxl-41 {
    order: 41;
  }
  .order-xxl-42 {
    order: 42;
  }
  .order-xxl-43 {
    order: 43;
  }
  .order-xxl-44 {
    order: 44;
  }
  .order-xxl-45 {
    order: 45;
  }
  .order-xxl-46 {
    order: 46;
  }
  .order-xxl-47 {
    order: 47;
  }
  .order-xxl-48 {
    order: 48;
  }
  .order-xxl-49 {
    order: 49;
  }
  .order-xxl-50 {
    order: 50;
  }
  .order-xxl-51 {
    order: 51;
  }
  .order-xxl-52 {
    order: 52;
  }
  .order-xxl-53 {
    order: 53;
  }
  .order-xxl-54 {
    order: 54;
  }
  .order-xxl-55 {
    order: 55;
  }
  .order-xxl-56 {
    order: 56;
  }
  .order-xxl-57 {
    order: 57;
  }
  .order-xxl-58 {
    order: 58;
  }
  .order-xxl-59 {
    order: 59;
  }
  .order-xxl-60 {
    order: 60;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 1.666667%;
  }
  .offset-xxl-2 {
    margin-left: 3.333333%;
  }
  .offset-xxl-3 {
    margin-left: 5%;
  }
  .offset-xxl-4 {
    margin-left: 6.666667%;
  }
  .offset-xxl-5 {
    margin-left: 8.333333%;
  }
  .offset-xxl-6 {
    margin-left: 10%;
  }
  .offset-xxl-7 {
    margin-left: 11.666667%;
  }
  .offset-xxl-8 {
    margin-left: 13.333333%;
  }
  .offset-xxl-9 {
    margin-left: 15%;
  }
  .offset-xxl-10 {
    margin-left: 16.666667%;
  }
  .offset-xxl-11 {
    margin-left: 18.333333%;
  }
  .offset-xxl-12 {
    margin-left: 20%;
  }
  .offset-xxl-13 {
    margin-left: 21.666667%;
  }
  .offset-xxl-14 {
    margin-left: 23.333333%;
  }
  .offset-xxl-15 {
    margin-left: 25%;
  }
  .offset-xxl-16 {
    margin-left: 26.666667%;
  }
  .offset-xxl-17 {
    margin-left: 28.333333%;
  }
  .offset-xxl-18 {
    margin-left: 30%;
  }
  .offset-xxl-19 {
    margin-left: 31.666667%;
  }
  .offset-xxl-20 {
    margin-left: 33.333333%;
  }
  .offset-xxl-21 {
    margin-left: 35%;
  }
  .offset-xxl-22 {
    margin-left: 36.666667%;
  }
  .offset-xxl-23 {
    margin-left: 38.333333%;
  }
  .offset-xxl-24 {
    margin-left: 40%;
  }
  .offset-xxl-25 {
    margin-left: 41.666667%;
  }
  .offset-xxl-26 {
    margin-left: 43.333333%;
  }
  .offset-xxl-27 {
    margin-left: 45%;
  }
  .offset-xxl-28 {
    margin-left: 46.666667%;
  }
  .offset-xxl-29 {
    margin-left: 48.333333%;
  }
  .offset-xxl-30 {
    margin-left: 50%;
  }
  .offset-xxl-31 {
    margin-left: 51.666667%;
  }
  .offset-xxl-32 {
    margin-left: 53.333333%;
  }
  .offset-xxl-33 {
    margin-left: 55%;
  }
  .offset-xxl-34 {
    margin-left: 56.666667%;
  }
  .offset-xxl-35 {
    margin-left: 58.333333%;
  }
  .offset-xxl-36 {
    margin-left: 60%;
  }
  .offset-xxl-37 {
    margin-left: 61.666667%;
  }
  .offset-xxl-38 {
    margin-left: 63.333333%;
  }
  .offset-xxl-39 {
    margin-left: 65%;
  }
  .offset-xxl-40 {
    margin-left: 66.666667%;
  }
  .offset-xxl-41 {
    margin-left: 68.333333%;
  }
  .offset-xxl-42 {
    margin-left: 70%;
  }
  .offset-xxl-43 {
    margin-left: 71.666667%;
  }
  .offset-xxl-44 {
    margin-left: 73.333333%;
  }
  .offset-xxl-45 {
    margin-left: 75%;
  }
  .offset-xxl-46 {
    margin-left: 76.666667%;
  }
  .offset-xxl-47 {
    margin-left: 78.333333%;
  }
  .offset-xxl-48 {
    margin-left: 80%;
  }
  .offset-xxl-49 {
    margin-left: 81.666667%;
  }
  .offset-xxl-50 {
    margin-left: 83.333333%;
  }
  .offset-xxl-51 {
    margin-left: 85%;
  }
  .offset-xxl-52 {
    margin-left: 86.666667%;
  }
  .offset-xxl-53 {
    margin-left: 88.333333%;
  }
  .offset-xxl-54 {
    margin-left: 90%;
  }
  .offset-xxl-55 {
    margin-left: 91.666667%;
  }
  .offset-xxl-56 {
    margin-left: 93.333333%;
  }
  .offset-xxl-57 {
    margin-left: 95%;
  }
  .offset-xxl-58 {
    margin-left: 96.666667%;
  }
  .offset-xxl-59 {
    margin-left: 98.333333%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table .table {
  background-color: #fff;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-dark-blue,
.table-dark-blue > th,
.table-dark-blue > td {
  background-color: #bdc8d8;
}

.table-hover .table-dark-blue:hover {
  background-color: #adbbcf;
}

.table-hover .table-dark-blue:hover > td,
.table-hover .table-dark-blue:hover > th {
  background-color: #adbbcf;
}

.table-blue,
.table-blue > th,
.table-blue > td {
  background-color: #bfcee3;
}

.table-hover .table-blue:hover {
  background-color: #adc0db;
}

.table-hover .table-blue:hover > td,
.table-hover .table-blue:hover > th {
  background-color: #adc0db;
}

.table-blue-opacity,
.table-blue-opacity > th,
.table-blue-opacity > td {
  background-color: rgba(243, 246, 250, 0.79);
}

.table-hover .table-blue-opacity:hover {
  background-color: rgba(225, 233, 243, 0.79);
}

.table-hover .table-blue-opacity:hover > td,
.table-hover .table-blue-opacity:hover > th {
  background-color: rgba(225, 233, 243, 0.79);
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f4c2c4;
}

.table-hover .table-danger:hover {
  background-color: #f0acaf;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f0acaf;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fcfdfe;
}

.table-hover .table-light:hover {
  background-color: #e9f0f8;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #e9f0f8;
}

.table-silver,
.table-silver > th,
.table-silver > td {
  background-color: #dfe4e9;
}

.table-hover .table-silver:hover {
  background-color: #d0d7df;
}

.table-hover .table-silver:hover > td,
.table-hover .table-silver:hover > th {
  background-color: #d0d7df;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-darkest,
.table-darkest > th,
.table-darkest > td {
  background-color: #c3c3c3;
}

.table-hover .table-darkest:hover {
  background-color: #b6b6b6;
}

.table-hover .table-darkest:hover > td,
.table-hover .table-darkest:hover > th {
  background-color: #b6b6b6;
}

.table-black,
.table-black > th,
.table-black > td {
  background-color: #b8b8b8;
}

.table-hover .table-black:hover {
  background-color: #ababab;
}

.table-hover .table-black:hover > td,
.table-hover .table-black:hover > th {
  background-color: #ababab;
}

.table-opacity,
.table-opacity > th,
.table-opacity > td {
  background-color: rgba(255, 255, 255, 0.72);
}

.table-hover .table-opacity:hover {
  background-color: rgba(242, 242, 242, 0.72);
}

.table-hover .table-opacity:hover > td,
.table-hover .table-opacity:hover > th {
  background-color: rgba(242, 242, 242, 0.72);
}

.table-opacity-25,
.table-opacity-25 > th,
.table-opacity-25 > td {
  background-color: rgba(255, 255, 255, 0.79);
}

.table-hover .table-opacity-25:hover {
  background-color: rgba(242, 242, 242, 0.79);
}

.table-hover .table-opacity-25:hover > td,
.table-hover .table-opacity-25:hover > th {
  background-color: rgba(242, 242, 242, 0.79);
}

.table-opacity-50,
.table-opacity-50 > th,
.table-opacity-50 > td {
  background-color: rgba(255, 255, 255, 0.86);
}

.table-hover .table-opacity-50:hover {
  background-color: rgba(242, 242, 242, 0.86);
}

.table-hover .table-opacity-50:hover > td,
.table-hover .table-opacity-50:hover > th {
  background-color: rgba(242, 242, 242, 0.86);
}

.table-opacity-75,
.table-opacity-75 > th,
.table-opacity-75 > td {
  background-color: rgba(255, 255, 255, 0.93);
}

.table-hover .table-opacity-75:hover {
  background-color: rgba(242, 242, 242, 0.93);
}

.table-hover .table-opacity-75:hover > td,
.table-hover .table-opacity-75:hover > th {
  background-color: rgba(242, 242, 242, 0.93);
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e;
}

.table .thead-light th {
  color: #495057;
  background-color: #ecf2f9;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #212529;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #32383e;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1599.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-xxl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #ec9194;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(217, 37, 43, 0.25);
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #ecf2f9;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745;
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
.custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip, .was-validated
.custom-select:valid ~ .valid-feedback,
.was-validated
.custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: #71dd8a;
}

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label::after, .custom-file-input.is-valid ~ .custom-file-label::after {
  border-color: inherit;
}

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #d9252b;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(217, 37, 43, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #d9252b;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
.custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #d9252b;
  box-shadow: 0 0 0 0.2rem rgba(217, 37, 43, 0.25);
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip, .was-validated
.custom-select:invalid ~ .invalid-feedback,
.was-validated
.custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #d9252b;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #d9252b;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  background-color: #ec9194;
}

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e15055;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(217, 37, 43, 0.25);
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #d9252b;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label::after, .custom-file-input.is-invalid ~ .custom-file-label::after {
  border-color: inherit;
}

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(217, 37, 43, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover, .btn:focus {
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(217, 37, 43, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-dark-blue {
  color: #fff;
  background-color: #133a72;
  border-color: #133a72;
}

.btn-dark-blue:hover {
  color: #fff;
  background-color: #0e2951;
  border-color: #0c2446;
}

.btn-dark-blue:focus, .btn-dark-blue.focus {
  box-shadow: 0 0 0 0.2rem rgba(19, 58, 114, 0.5);
}

.btn-dark-blue.disabled, .btn-dark-blue:disabled {
  color: #fff;
  background-color: #133a72;
  border-color: #133a72;
}

.btn-dark-blue:not(:disabled):not(.disabled):active, .btn-dark-blue:not(:disabled):not(.disabled).active,
.show > .btn-dark-blue.dropdown-toggle {
  color: #fff;
  background-color: #0c2446;
  border-color: #0a1e3b;
}

.btn-dark-blue:not(:disabled):not(.disabled):active:focus, .btn-dark-blue:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(19, 58, 114, 0.5);
}

.btn-blue {
  color: #fff;
  background-color: #1a4f9a;
  border-color: #1a4f9a;
}

.btn-blue:hover {
  color: #fff;
  background-color: #143e79;
  border-color: #13396e;
}

.btn-blue:focus, .btn-blue.focus {
  box-shadow: 0 0 0 0.2rem rgba(26, 79, 154, 0.5);
}

.btn-blue.disabled, .btn-blue:disabled {
  color: #fff;
  background-color: #1a4f9a;
  border-color: #1a4f9a;
}

.btn-blue:not(:disabled):not(.disabled):active, .btn-blue:not(:disabled):not(.disabled).active,
.show > .btn-blue.dropdown-toggle {
  color: #fff;
  background-color: #13396e;
  border-color: #113363;
}

.btn-blue:not(:disabled):not(.disabled):active:focus, .btn-blue:not(:disabled):not(.disabled).active:focus,
.show > .btn-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(26, 79, 154, 0.5);
}

.btn-blue-opacity {
  color: #fff;
  background-color: rgba(26, 79, 154, 0.25);
  border-color: rgba(26, 79, 154, 0.25);
}

.btn-blue-opacity:hover {
  color: #fff;
  background-color: rgba(20, 62, 121, 0.25);
  border-color: rgba(19, 57, 110, 0.25);
}

.btn-blue-opacity:focus, .btn-blue-opacity.focus {
  box-shadow: 0 0 0 0.2rem rgba(26, 79, 154, 0.5);
}

.btn-blue-opacity.disabled, .btn-blue-opacity:disabled {
  color: #fff;
  background-color: rgba(26, 79, 154, 0.25);
  border-color: rgba(26, 79, 154, 0.25);
}

.btn-blue-opacity:not(:disabled):not(.disabled):active, .btn-blue-opacity:not(:disabled):not(.disabled).active,
.show > .btn-blue-opacity.dropdown-toggle {
  color: #fff;
  background-color: rgba(19, 57, 110, 0.25);
  border-color: rgba(17, 51, 99, 0.25);
}

.btn-blue-opacity:not(:disabled):not(.disabled):active:focus, .btn-blue-opacity:not(:disabled):not(.disabled).active:focus,
.show > .btn-blue-opacity.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(26, 79, 154, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #d9252b;
  border-color: #d9252b;
}

.btn-danger:hover {
  color: #fff;
  background-color: #b81f25;
  border-color: #ad1e22;
}

.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(217, 37, 43, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #d9252b;
  border-color: #d9252b;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #ad1e22;
  border-color: #a31c20;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(217, 37, 43, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f5f8fc;
  border-color: #f5f8fc;
}

.btn-light:hover {
  color: #212529;
  background-color: #d8e3f3;
  border-color: #ceddf0;
}

.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(245, 248, 252, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f5f8fc;
  border-color: #f5f8fc;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #ceddf0;
  border-color: #c4d6ed;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(245, 248, 252, 0.5);
}

.btn-silver {
  color: #212529;
  background-color: #8d9fb2;
  border-color: #8d9fb2;
}

.btn-silver:hover {
  color: #fff;
  background-color: #768ca3;
  border-color: #6f859d;
}

.btn-silver:focus, .btn-silver.focus {
  box-shadow: 0 0 0 0.2rem rgba(141, 159, 178, 0.5);
}

.btn-silver.disabled, .btn-silver:disabled {
  color: #212529;
  background-color: #8d9fb2;
  border-color: #8d9fb2;
}

.btn-silver:not(:disabled):not(.disabled):active, .btn-silver:not(:disabled):not(.disabled).active,
.show > .btn-silver.dropdown-toggle {
  color: #fff;
  background-color: #6f859d;
  border-color: #677f98;
}

.btn-silver:not(:disabled):not(.disabled):active:focus, .btn-silver:not(:disabled):not(.disabled).active:focus,
.show > .btn-silver.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(141, 159, 178, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-darkest {
  color: #fff;
  background-color: #282828;
  border-color: #282828;
}

.btn-darkest:hover {
  color: #fff;
  background-color: #151515;
  border-color: #0f0f0f;
}

.btn-darkest:focus, .btn-darkest.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 40, 40, 0.5);
}

.btn-darkest.disabled, .btn-darkest:disabled {
  color: #fff;
  background-color: #282828;
  border-color: #282828;
}

.btn-darkest:not(:disabled):not(.disabled):active, .btn-darkest:not(:disabled):not(.disabled).active,
.show > .btn-darkest.dropdown-toggle {
  color: #fff;
  background-color: #0f0f0f;
  border-color: #080808;
}

.btn-darkest:not(:disabled):not(.disabled):active:focus, .btn-darkest:not(:disabled):not(.disabled).active:focus,
.show > .btn-darkest.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 40, 40, 0.5);
}

.btn-black {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-black:hover {
  color: #fff;
  background-color: black;
  border-color: black;
}

.btn-black:focus, .btn-black.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-black.disabled, .btn-black:disabled {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-black:not(:disabled):not(.disabled):active, .btn-black:not(:disabled):not(.disabled).active,
.show > .btn-black.dropdown-toggle {
  color: #fff;
  background-color: black;
  border-color: black;
}

.btn-black:not(:disabled):not(.disabled):active:focus, .btn-black:not(:disabled):not(.disabled).active:focus,
.show > .btn-black.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-opacity {
  color: #212529;
  background-color: rgba(255, 255, 255, 0);
  border-color: rgba(255, 255, 255, 0);
}

.btn-opacity:hover {
  color: #212529;
  background-color: rgba(236, 236, 236, 0);
  border-color: rgba(230, 230, 230, 0);
}

.btn-opacity:focus, .btn-opacity.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-opacity.disabled, .btn-opacity:disabled {
  color: #212529;
  background-color: rgba(255, 255, 255, 0);
  border-color: rgba(255, 255, 255, 0);
}

.btn-opacity:not(:disabled):not(.disabled):active, .btn-opacity:not(:disabled):not(.disabled).active,
.show > .btn-opacity.dropdown-toggle {
  color: #212529;
  background-color: rgba(230, 230, 230, 0);
  border-color: rgba(223, 223, 223, 0);
}

.btn-opacity:not(:disabled):not(.disabled):active:focus, .btn-opacity:not(:disabled):not(.disabled).active:focus,
.show > .btn-opacity.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-opacity-25 {
  color: #212529;
  background-color: rgba(255, 255, 255, 0.25);
  border-color: rgba(255, 255, 255, 0.25);
}

.btn-opacity-25:hover {
  color: #212529;
  background-color: rgba(236, 236, 236, 0.25);
  border-color: rgba(230, 230, 230, 0.25);
}

.btn-opacity-25:focus, .btn-opacity-25.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-opacity-25.disabled, .btn-opacity-25:disabled {
  color: #212529;
  background-color: rgba(255, 255, 255, 0.25);
  border-color: rgba(255, 255, 255, 0.25);
}

.btn-opacity-25:not(:disabled):not(.disabled):active, .btn-opacity-25:not(:disabled):not(.disabled).active,
.show > .btn-opacity-25.dropdown-toggle {
  color: #212529;
  background-color: rgba(230, 230, 230, 0.25);
  border-color: rgba(223, 223, 223, 0.25);
}

.btn-opacity-25:not(:disabled):not(.disabled):active:focus, .btn-opacity-25:not(:disabled):not(.disabled).active:focus,
.show > .btn-opacity-25.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-opacity-50 {
  color: #212529;
  background-color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.5);
}

.btn-opacity-50:hover {
  color: #212529;
  background-color: rgba(236, 236, 236, 0.5);
  border-color: rgba(230, 230, 230, 0.5);
}

.btn-opacity-50:focus, .btn-opacity-50.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-opacity-50.disabled, .btn-opacity-50:disabled {
  color: #212529;
  background-color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.5);
}

.btn-opacity-50:not(:disabled):not(.disabled):active, .btn-opacity-50:not(:disabled):not(.disabled).active,
.show > .btn-opacity-50.dropdown-toggle {
  color: #212529;
  background-color: rgba(230, 230, 230, 0.5);
  border-color: rgba(223, 223, 223, 0.5);
}

.btn-opacity-50:not(:disabled):not(.disabled):active:focus, .btn-opacity-50:not(:disabled):not(.disabled).active:focus,
.show > .btn-opacity-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-opacity-75 {
  color: #212529;
  background-color: rgba(255, 255, 255, 0.75);
  border-color: rgba(255, 255, 255, 0.75);
}

.btn-opacity-75:hover {
  color: #212529;
  background-color: rgba(236, 236, 236, 0.75);
  border-color: rgba(230, 230, 230, 0.75);
}

.btn-opacity-75:focus, .btn-opacity-75.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-opacity-75.disabled, .btn-opacity-75:disabled {
  color: #212529;
  background-color: rgba(255, 255, 255, 0.75);
  border-color: rgba(255, 255, 255, 0.75);
}

.btn-opacity-75:not(:disabled):not(.disabled):active, .btn-opacity-75:not(:disabled):not(.disabled).active,
.show > .btn-opacity-75.dropdown-toggle {
  color: #212529;
  background-color: rgba(230, 230, 230, 0.75);
  border-color: rgba(223, 223, 223, 0.75);
}

.btn-opacity-75:not(:disabled):not(.disabled):active:focus, .btn-opacity-75:not(:disabled):not(.disabled).active:focus,
.show > .btn-opacity-75.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-dark-blue {
  color: #133a72;
  background-color: transparent;
  background-image: none;
  border-color: #133a72;
}

.btn-outline-dark-blue:hover {
  color: #fff;
  background-color: #133a72;
  border-color: #133a72;
}

.btn-outline-dark-blue:focus, .btn-outline-dark-blue.focus {
  box-shadow: 0 0 0 0.2rem rgba(19, 58, 114, 0.5);
}

.btn-outline-dark-blue.disabled, .btn-outline-dark-blue:disabled {
  color: #133a72;
  background-color: transparent;
}

.btn-outline-dark-blue:not(:disabled):not(.disabled):active, .btn-outline-dark-blue:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark-blue.dropdown-toggle {
  color: #fff;
  background-color: #133a72;
  border-color: #133a72;
}

.btn-outline-dark-blue:not(:disabled):not(.disabled):active:focus, .btn-outline-dark-blue:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(19, 58, 114, 0.5);
}

.btn-outline-blue {
  color: #1a4f9a;
  background-color: transparent;
  background-image: none;
  border-color: #1a4f9a;
}

.btn-outline-blue:hover {
  color: #fff;
  background-color: #1a4f9a;
  border-color: #1a4f9a;
}

.btn-outline-blue:focus, .btn-outline-blue.focus {
  box-shadow: 0 0 0 0.2rem rgba(26, 79, 154, 0.5);
}

.btn-outline-blue.disabled, .btn-outline-blue:disabled {
  color: #1a4f9a;
  background-color: transparent;
}

.btn-outline-blue:not(:disabled):not(.disabled):active, .btn-outline-blue:not(:disabled):not(.disabled).active,
.show > .btn-outline-blue.dropdown-toggle {
  color: #fff;
  background-color: #1a4f9a;
  border-color: #1a4f9a;
}

.btn-outline-blue:not(:disabled):not(.disabled):active:focus, .btn-outline-blue:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(26, 79, 154, 0.5);
}

.btn-outline-blue-opacity {
  color: rgba(26, 79, 154, 0.25);
  background-color: transparent;
  background-image: none;
  border-color: rgba(26, 79, 154, 0.25);
}

.btn-outline-blue-opacity:hover {
  color: #fff;
  background-color: rgba(26, 79, 154, 0.25);
  border-color: rgba(26, 79, 154, 0.25);
}

.btn-outline-blue-opacity:focus, .btn-outline-blue-opacity.focus {
  box-shadow: 0 0 0 0.2rem rgba(26, 79, 154, 0.5);
}

.btn-outline-blue-opacity.disabled, .btn-outline-blue-opacity:disabled {
  color: rgba(26, 79, 154, 0.25);
  background-color: transparent;
}

.btn-outline-blue-opacity:not(:disabled):not(.disabled):active, .btn-outline-blue-opacity:not(:disabled):not(.disabled).active,
.show > .btn-outline-blue-opacity.dropdown-toggle {
  color: #fff;
  background-color: rgba(26, 79, 154, 0.25);
  border-color: rgba(26, 79, 154, 0.25);
}

.btn-outline-blue-opacity:not(:disabled):not(.disabled):active:focus, .btn-outline-blue-opacity:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-blue-opacity.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(26, 79, 154, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #d9252b;
  background-color: transparent;
  background-image: none;
  border-color: #d9252b;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #d9252b;
  border-color: #d9252b;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(217, 37, 43, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #d9252b;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #d9252b;
  border-color: #d9252b;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(217, 37, 43, 0.5);
}

.btn-outline-light {
  color: #f5f8fc;
  background-color: transparent;
  background-image: none;
  border-color: #f5f8fc;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f5f8fc;
  border-color: #f5f8fc;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(245, 248, 252, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f5f8fc;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f5f8fc;
  border-color: #f5f8fc;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(245, 248, 252, 0.5);
}

.btn-outline-silver {
  color: #8d9fb2;
  background-color: transparent;
  background-image: none;
  border-color: #8d9fb2;
}

.btn-outline-silver:hover {
  color: #212529;
  background-color: #8d9fb2;
  border-color: #8d9fb2;
}

.btn-outline-silver:focus, .btn-outline-silver.focus {
  box-shadow: 0 0 0 0.2rem rgba(141, 159, 178, 0.5);
}

.btn-outline-silver.disabled, .btn-outline-silver:disabled {
  color: #8d9fb2;
  background-color: transparent;
}

.btn-outline-silver:not(:disabled):not(.disabled):active, .btn-outline-silver:not(:disabled):not(.disabled).active,
.show > .btn-outline-silver.dropdown-toggle {
  color: #212529;
  background-color: #8d9fb2;
  border-color: #8d9fb2;
}

.btn-outline-silver:not(:disabled):not(.disabled):active:focus, .btn-outline-silver:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-silver.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(141, 159, 178, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-darkest {
  color: #282828;
  background-color: transparent;
  background-image: none;
  border-color: #282828;
}

.btn-outline-darkest:hover {
  color: #fff;
  background-color: #282828;
  border-color: #282828;
}

.btn-outline-darkest:focus, .btn-outline-darkest.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 40, 40, 0.5);
}

.btn-outline-darkest.disabled, .btn-outline-darkest:disabled {
  color: #282828;
  background-color: transparent;
}

.btn-outline-darkest:not(:disabled):not(.disabled):active, .btn-outline-darkest:not(:disabled):not(.disabled).active,
.show > .btn-outline-darkest.dropdown-toggle {
  color: #fff;
  background-color: #282828;
  border-color: #282828;
}

.btn-outline-darkest:not(:disabled):not(.disabled):active:focus, .btn-outline-darkest:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-darkest.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 40, 40, 0.5);
}

.btn-outline-black {
  color: #000;
  background-color: transparent;
  background-image: none;
  border-color: #000;
}

.btn-outline-black:hover {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-outline-black:focus, .btn-outline-black.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-outline-black.disabled, .btn-outline-black:disabled {
  color: #000;
  background-color: transparent;
}

.btn-outline-black:not(:disabled):not(.disabled):active, .btn-outline-black:not(:disabled):not(.disabled).active,
.show > .btn-outline-black.dropdown-toggle {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-outline-black:not(:disabled):not(.disabled):active:focus, .btn-outline-black:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-black.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-outline-opacity {
  color: rgba(255, 255, 255, 0);
  background-color: transparent;
  background-image: none;
  border-color: rgba(255, 255, 255, 0);
}

.btn-outline-opacity:hover {
  color: #212529;
  background-color: rgba(255, 255, 255, 0);
  border-color: rgba(255, 255, 255, 0);
}

.btn-outline-opacity:focus, .btn-outline-opacity.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-opacity.disabled, .btn-outline-opacity:disabled {
  color: rgba(255, 255, 255, 0);
  background-color: transparent;
}

.btn-outline-opacity:not(:disabled):not(.disabled):active, .btn-outline-opacity:not(:disabled):not(.disabled).active,
.show > .btn-outline-opacity.dropdown-toggle {
  color: #212529;
  background-color: rgba(255, 255, 255, 0);
  border-color: rgba(255, 255, 255, 0);
}

.btn-outline-opacity:not(:disabled):not(.disabled):active:focus, .btn-outline-opacity:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-opacity.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-opacity-25 {
  color: rgba(255, 255, 255, 0.25);
  background-color: transparent;
  background-image: none;
  border-color: rgba(255, 255, 255, 0.25);
}

.btn-outline-opacity-25:hover {
  color: #212529;
  background-color: rgba(255, 255, 255, 0.25);
  border-color: rgba(255, 255, 255, 0.25);
}

.btn-outline-opacity-25:focus, .btn-outline-opacity-25.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-opacity-25.disabled, .btn-outline-opacity-25:disabled {
  color: rgba(255, 255, 255, 0.25);
  background-color: transparent;
}

.btn-outline-opacity-25:not(:disabled):not(.disabled):active, .btn-outline-opacity-25:not(:disabled):not(.disabled).active,
.show > .btn-outline-opacity-25.dropdown-toggle {
  color: #212529;
  background-color: rgba(255, 255, 255, 0.25);
  border-color: rgba(255, 255, 255, 0.25);
}

.btn-outline-opacity-25:not(:disabled):not(.disabled):active:focus, .btn-outline-opacity-25:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-opacity-25.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-opacity-50 {
  color: rgba(255, 255, 255, 0.5);
  background-color: transparent;
  background-image: none;
  border-color: rgba(255, 255, 255, 0.5);
}

.btn-outline-opacity-50:hover {
  color: #212529;
  background-color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.5);
}

.btn-outline-opacity-50:focus, .btn-outline-opacity-50.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-opacity-50.disabled, .btn-outline-opacity-50:disabled {
  color: rgba(255, 255, 255, 0.5);
  background-color: transparent;
}

.btn-outline-opacity-50:not(:disabled):not(.disabled):active, .btn-outline-opacity-50:not(:disabled):not(.disabled).active,
.show > .btn-outline-opacity-50.dropdown-toggle {
  color: #212529;
  background-color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.5);
}

.btn-outline-opacity-50:not(:disabled):not(.disabled):active:focus, .btn-outline-opacity-50:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-opacity-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-opacity-75 {
  color: rgba(255, 255, 255, 0.75);
  background-color: transparent;
  background-image: none;
  border-color: rgba(255, 255, 255, 0.75);
}

.btn-outline-opacity-75:hover {
  color: #212529;
  background-color: rgba(255, 255, 255, 0.75);
  border-color: rgba(255, 255, 255, 0.75);
}

.btn-outline-opacity-75:focus, .btn-outline-opacity-75.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-opacity-75.disabled, .btn-outline-opacity-75:disabled {
  color: rgba(255, 255, 255, 0.75);
  background-color: transparent;
}

.btn-outline-opacity-75:not(:disabled):not(.disabled):active, .btn-outline-opacity-75:not(:disabled):not(.disabled).active,
.show > .btn-outline-opacity-75.dropdown-toggle {
  color: #212529;
  background-color: rgba(255, 255, 255, 0.75);
  border-color: rgba(255, 255, 255, 0.75);
}

.btn-outline-opacity-75:not(:disabled):not(.disabled):active:focus, .btn-outline-opacity-75:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-opacity-75.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #1a4f9a;
  background-color: transparent;
}

.btn-link:hover {
  color: #0f2d59;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  border-color: transparent;
  box-shadow: none;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media screen and (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 8rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #ecf2f9;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f5f8fc;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #d9252b;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 0 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group {
  margin-left: -1px;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:first-child {
  margin-left: 0;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #ecf2f9;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 0.25rem;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #d9252b;
}

.custom-control-input:focus ~ .custom-control-label::before {
  outline: none;
  box-shadow: none;
}

.custom-control-input:active ~ .custom-control-label::before {
  color: #fff;
  background-color: #f4bdbf;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #ecf2f9;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  box-sizing: border-box;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  user-select: none;
  background-color: #fff;
  border: 2px solid #ecf2f9;
  border-radius: 4px;
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.custom-checkbox .custom-control-label {
  font-size: 0.875rem;
  line-height: 1.75rem;
  color: #000;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #d9252b;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #d9252b;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
  border-radius: 4px;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #d9252b;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio {
  min-height: 2rem;
  padding-left: 2.5rem;
}

.custom-radio .custom-control-label {
  font-size: 0.875rem;
  line-height: 1.75rem;
  color: #000;
}

.custom-radio .custom-control-label::before {
  left: -2.5rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  left: -2.5rem;
  width: 1.25rem;
  height: 1.25rem;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  top: .5rem;
  left: -2.25rem;
  width: .75rem;
  height: .75rem;
  background-color: #d9252b;
  border-radius: 50%;
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 0.25rem;
  appearance: none;
}

.custom-select:focus {
  border-color: #ec9194;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(236, 145, 148, 0.5);
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #ecf2f9;
}

.custom-select::-ms-expand {
  opacity: 0;
}

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%;
}

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #ec9194;
  box-shadow: 0 0 0 0.2rem rgba(217, 37, 43, 0.25);
}

.custom-file-input:focus ~ .custom-file-label::after {
  border-color: #ec9194;
}

.custom-file-input:disabled ~ .custom-file-label {
  background-color: #ecf2f9;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 0.25rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 2.25rem;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #ecf2f9;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  padding-left: 0;
  background-color: transparent;
  appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(217, 37, 43, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(217, 37, 43, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(217, 37, 43, 0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #d9252b;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #f4bdbf;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #d9252b;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #f4bdbf;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #d9252b;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #f4bdbf;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #ecf2f9 #ecf2f9 #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #d9252b;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1599.98px) {
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1600px) {
  .navbar-expand-xxl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  box-shadow: 0 4px 16px #f0f0f0;
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: rgba(40, 40, 40, 0.87);
}

.card-body label {
  font-size: 0.875rem;
  line-height: 1.5rem;
}

.card-title {
  padding: .25rem 1.25rem;
  margin-bottom: 0.75rem;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 12px;
  font-weight: 500;
  line-height: 36px;
  color: #282828;
  text-transform: uppercase;
  border-bottom: 1px solid #f5f8fc;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 0 solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 0) calc(0.25rem - 0) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 0 solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 0) calc(0.25rem - 0);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 0);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 0);
  border-top-right-radius: calc(0.25rem - 0);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 0);
  border-bottom-left-radius: calc(0.25rem - 0);
}

.card-deck {
  display: flex;
  flex-direction: column;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group {
  display: flex;
  flex-direction: column;
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:first-child .card-img-top,
  .card-group > .card:first-child .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:first-child .card-img-bottom,
  .card-group > .card:first-child .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:last-child .card-img-top,
  .card-group > .card:last-child .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:last-child .card-img-bottom,
  .card-group > .card:last-child .card-footer {
    border-bottom-left-radius: 0;
  }
  .card-group > .card:only-child {
    border-radius: 0.25rem;
  }
  .card-group > .card:only-child .card-img-top,
  .card-group > .card:only-child .card-header {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .card-group > .card:only-child .card-img-bottom,
  .card-group > .card:only-child .card-footer {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
    border-radius: 0;
  }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
    border-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}

.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}

.accordion .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #ecf2f9;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  justify-content: center;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-right: .25rem;
  margin-left: .25rem;
  line-height: 1rem;
  color: #1a4f9a;
  background-color: none;
  border: 0 solid none;
  border-radius: 50%;
}

.page-link:hover {
  z-index: 2;
  color: #fff;
  text-decoration: none;
  background-color: #1a4f9a;
  border-color: #1a4f9a;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(217, 37, 43, 0.25);
}

.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.page-item:first-child .page-link {
  margin-left: 0;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #1a4f9a;
  border-color: #1a4f9a;
  box-shadow: none;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}

.badge-primary[href]:hover, .badge-primary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #0062cc;
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

.badge-secondary[href]:hover, .badge-secondary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #545b62;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

.badge-success[href]:hover, .badge-success[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1e7e34;
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

.badge-info[href]:hover, .badge-info[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #117a8b;
}

.badge-dark-blue {
  color: #fff;
  background-color: #133a72;
}

.badge-dark-blue[href]:hover, .badge-dark-blue[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #0c2446;
}

.badge-blue {
  color: #fff;
  background-color: #1a4f9a;
}

.badge-blue[href]:hover, .badge-blue[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #13396e;
}

.badge-blue-opacity {
  color: #fff;
  background-color: rgba(26, 79, 154, 0.25);
}

.badge-blue-opacity[href]:hover, .badge-blue-opacity[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: rgba(19, 57, 110, 0.25);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

.badge-warning[href]:hover, .badge-warning[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #d39e00;
}

.badge-danger {
  color: #fff;
  background-color: #d9252b;
}

.badge-danger[href]:hover, .badge-danger[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #ad1e22;
}

.badge-light {
  color: #212529;
  background-color: #f5f8fc;
}

.badge-light[href]:hover, .badge-light[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #ceddf0;
}

.badge-silver {
  color: #212529;
  background-color: #8d9fb2;
}

.badge-silver[href]:hover, .badge-silver[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #6f859d;
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

.badge-dark[href]:hover, .badge-dark[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1d2124;
}

.badge-darkest {
  color: #fff;
  background-color: #282828;
}

.badge-darkest[href]:hover, .badge-darkest[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #0f0f0f;
}

.badge-black {
  color: #fff;
  background-color: #000;
}

.badge-black[href]:hover, .badge-black[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: black;
}

.badge-opacity {
  color: #212529;
  background-color: rgba(255, 255, 255, 0);
}

.badge-opacity[href]:hover, .badge-opacity[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: rgba(230, 230, 230, 0);
}

.badge-opacity-25 {
  color: #212529;
  background-color: rgba(255, 255, 255, 0.25);
}

.badge-opacity-25[href]:hover, .badge-opacity-25[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: rgba(230, 230, 230, 0.25);
}

.badge-opacity-50 {
  color: #212529;
  background-color: rgba(255, 255, 255, 0.5);
}

.badge-opacity-50[href]:hover, .badge-opacity-50[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: rgba(230, 230, 230, 0.5);
}

.badge-opacity-75 {
  color: #212529;
  background-color: rgba(255, 255, 255, 0.75);
}

.badge-opacity-75[href]:hover, .badge-opacity-75[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: rgba(230, 230, 230, 0.75);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #ecf2f9;
  border-radius: 0.3rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.alert-primary hr {
  border-top-color: #9fcdff;
}

.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-secondary hr {
  border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-success hr {
  border-top-color: #b1dfbb;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-dark-blue {
  color: #0a1e3b;
  background-color: #d0d8e3;
  border-color: #bdc8d8;
}

.alert-dark-blue hr {
  border-top-color: #adbbcf;
}

.alert-dark-blue .alert-link {
  color: #03080f;
}

.alert-blue {
  color: #0e2950;
  background-color: #d1dceb;
  border-color: #bfcee3;
}

.alert-blue hr {
  border-top-color: #adc0db;
}

.alert-blue .alert-link {
  color: #061325;
}

.alert-blue-opacity {
  color: rgba(3, 11, 21, 0.61);
  background-color: rgba(247, 249, 252, 0.85);
  border-color: rgba(243, 246, 250, 0.79);
}

.alert-blue-opacity hr {
  border-top-color: rgba(225, 233, 243, 0.79);
}

.alert-blue-opacity .alert-link {
  color: rgba(0, 0, 0, 0.61);
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #711316;
  background-color: #f7d3d5;
  border-color: #f4c2c4;
}

.alert-danger hr {
  border-top-color: #f0acaf;
}

.alert-danger .alert-link {
  color: #450c0e;
}

.alert-light {
  color: #7f8183;
  background-color: #fdfefe;
  border-color: #fcfdfe;
}

.alert-light hr {
  border-top-color: #e9f0f8;
}

.alert-light .alert-link {
  color: #666869;
}

.alert-silver {
  color: #49535d;
  background-color: #e8ecf0;
  border-color: #dfe4e9;
}

.alert-silver hr {
  border-top-color: #d0d7df;
}

.alert-silver .alert-link {
  color: #333a40;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

.alert-darkest {
  color: #151515;
  background-color: #d4d4d4;
  border-color: #c3c3c3;
}

.alert-darkest hr {
  border-top-color: #b6b6b6;
}

.alert-darkest .alert-link {
  color: black;
}

.alert-black {
  color: black;
  background-color: #cccccc;
  border-color: #b8b8b8;
}

.alert-black hr {
  border-top-color: #ababab;
}

.alert-black .alert-link {
  color: black;
}

.alert-opacity {
  color: rgba(0, 0, 0, 0.48);
  background-color: rgba(255, 255, 255, 0.8);
  border-color: rgba(255, 255, 255, 0.72);
}

.alert-opacity hr {
  border-top-color: rgba(242, 242, 242, 0.72);
}

.alert-opacity .alert-link {
  color: rgba(0, 0, 0, 0.48);
}

.alert-opacity-25 {
  color: rgba(34, 34, 34, 0.61);
  background-color: rgba(255, 255, 255, 0.85);
  border-color: rgba(255, 255, 255, 0.79);
}

.alert-opacity-25 hr {
  border-top-color: rgba(242, 242, 242, 0.79);
}

.alert-opacity-25 .alert-link {
  color: rgba(9, 9, 9, 0.61);
}

.alert-opacity-50 {
  color: rgba(68, 68, 68, 0.74);
  background-color: rgba(255, 255, 255, 0.9);
  border-color: rgba(255, 255, 255, 0.86);
}

.alert-opacity-50 hr {
  border-top-color: rgba(242, 242, 242, 0.86);
}

.alert-opacity-50 .alert-link {
  color: rgba(43, 43, 43, 0.74);
}

.alert-opacity-75 {
  color: rgba(100, 100, 100, 0.87);
  background-color: rgba(255, 255, 255, 0.95);
  border-color: rgba(255, 255, 255, 0.93);
}

.alert-opacity-75 hr {
  border-top-color: rgba(242, 242, 242, 0.93);
}

.alert-opacity-75 .alert-link {
  color: rgba(75, 75, 75, 0.87);
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #ecf2f9;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  color: #495057;
  text-decoration: none;
  background-color: #f5f8fc;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #ecf2f9;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.list-group-item:hover, .list-group-item:focus {
  z-index: 1;
  text-decoration: none;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #d9252b;
  border-color: #d9252b;
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-dark-blue {
  color: #0a1e3b;
  background-color: #bdc8d8;
}

.list-group-item-dark-blue.list-group-item-action:hover, .list-group-item-dark-blue.list-group-item-action:focus {
  color: #0a1e3b;
  background-color: #adbbcf;
}

.list-group-item-dark-blue.list-group-item-action.active {
  color: #fff;
  background-color: #0a1e3b;
  border-color: #0a1e3b;
}

.list-group-item-blue {
  color: #0e2950;
  background-color: #bfcee3;
}

.list-group-item-blue.list-group-item-action:hover, .list-group-item-blue.list-group-item-action:focus {
  color: #0e2950;
  background-color: #adc0db;
}

.list-group-item-blue.list-group-item-action.active {
  color: #fff;
  background-color: #0e2950;
  border-color: #0e2950;
}

.list-group-item-blue-opacity {
  color: rgba(3, 11, 21, 0.61);
  background-color: rgba(243, 246, 250, 0.79);
}

.list-group-item-blue-opacity.list-group-item-action:hover, .list-group-item-blue-opacity.list-group-item-action:focus {
  color: rgba(3, 11, 21, 0.61);
  background-color: rgba(225, 233, 243, 0.79);
}

.list-group-item-blue-opacity.list-group-item-action.active {
  color: #fff;
  background-color: rgba(3, 11, 21, 0.61);
  border-color: rgba(3, 11, 21, 0.61);
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #711316;
  background-color: #f4c2c4;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #711316;
  background-color: #f0acaf;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #711316;
  border-color: #711316;
}

.list-group-item-light {
  color: #7f8183;
  background-color: #fcfdfe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #7f8183;
  background-color: #e9f0f8;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #7f8183;
  border-color: #7f8183;
}

.list-group-item-silver {
  color: #49535d;
  background-color: #dfe4e9;
}

.list-group-item-silver.list-group-item-action:hover, .list-group-item-silver.list-group-item-action:focus {
  color: #49535d;
  background-color: #d0d7df;
}

.list-group-item-silver.list-group-item-action.active {
  color: #fff;
  background-color: #49535d;
  border-color: #49535d;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.list-group-item-darkest {
  color: #151515;
  background-color: #c3c3c3;
}

.list-group-item-darkest.list-group-item-action:hover, .list-group-item-darkest.list-group-item-action:focus {
  color: #151515;
  background-color: #b6b6b6;
}

.list-group-item-darkest.list-group-item-action.active {
  color: #fff;
  background-color: #151515;
  border-color: #151515;
}

.list-group-item-black {
  color: black;
  background-color: #b8b8b8;
}

.list-group-item-black.list-group-item-action:hover, .list-group-item-black.list-group-item-action:focus {
  color: black;
  background-color: #ababab;
}

.list-group-item-black.list-group-item-action.active {
  color: #fff;
  background-color: black;
  border-color: black;
}

.list-group-item-opacity {
  color: rgba(0, 0, 0, 0.48);
  background-color: rgba(255, 255, 255, 0.72);
}

.list-group-item-opacity.list-group-item-action:hover, .list-group-item-opacity.list-group-item-action:focus {
  color: rgba(0, 0, 0, 0.48);
  background-color: rgba(242, 242, 242, 0.72);
}

.list-group-item-opacity.list-group-item-action.active {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.48);
  border-color: rgba(0, 0, 0, 0.48);
}

.list-group-item-opacity-25 {
  color: rgba(34, 34, 34, 0.61);
  background-color: rgba(255, 255, 255, 0.79);
}

.list-group-item-opacity-25.list-group-item-action:hover, .list-group-item-opacity-25.list-group-item-action:focus {
  color: rgba(34, 34, 34, 0.61);
  background-color: rgba(242, 242, 242, 0.79);
}

.list-group-item-opacity-25.list-group-item-action.active {
  color: #fff;
  background-color: rgba(34, 34, 34, 0.61);
  border-color: rgba(34, 34, 34, 0.61);
}

.list-group-item-opacity-50 {
  color: rgba(68, 68, 68, 0.74);
  background-color: rgba(255, 255, 255, 0.86);
}

.list-group-item-opacity-50.list-group-item-action:hover, .list-group-item-opacity-50.list-group-item-action:focus {
  color: rgba(68, 68, 68, 0.74);
  background-color: rgba(242, 242, 242, 0.86);
}

.list-group-item-opacity-50.list-group-item-action.active {
  color: #fff;
  background-color: rgba(68, 68, 68, 0.74);
  border-color: rgba(68, 68, 68, 0.74);
}

.list-group-item-opacity-75 {
  color: rgba(100, 100, 100, 0.87);
  background-color: rgba(255, 255, 255, 0.93);
}

.list-group-item-opacity-75.list-group-item-action:hover, .list-group-item-opacity-75.list-group-item-action:focus {
  color: rgba(100, 100, 100, 0.87);
  background-color: rgba(242, 242, 242, 0.93);
}

.list-group-item-opacity-75.list-group-item-action.active {
  color: #fff;
  background-color: rgba(100, 100, 100, 0.87);
  border-color: rgba(100, 100, 100, 0.87);
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  color: #000;
  text-decoration: none;
  opacity: .75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -25%);
}

@media screen and (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: translate(0, 0);
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - (0.5rem * 2));
  content: "";
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #ecf2f9;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #ecf2f9;
}

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .modal-dialog-centered::before {
    height: calc(100vh - (1.75rem * 2));
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}

.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-width: 0.5rem 0.5rem 0;
}

.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
  bottom: 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}


.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  bottom: 1px;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  border-width: 0.5rem 0.5rem 0.5rem 0;
}

.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
  left: 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}


.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  left: 1px;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
  top: calc((0.5rem + 1px) * -1);
}

.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-width: 0 0.5rem 0.5rem 0.5rem;
}

.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
  top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}


.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  top: 1px;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  border-width: 0.5rem 0 0.5rem 0.5rem;
}

.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
  right: 0;
  border-left-color: rgba(0, 0, 0, 0.25);
}


.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  right: 1px;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: #000;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  backface-visibility: hidden;
  perspective: 1000px;
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: flex;
  transition: transform 0.6s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .carousel-item.active,
  .carousel-item-next,
  .carousel-item-prev {
    transition: none;
  }
}

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0;
}

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0);
}

@supports (transform-style: preserve-3d) {
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    transform: translate3d(0, 0, 0);
  }
}

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%);
}

@supports (transform-style: preserve-3d) {
  .carousel-item-next,
  .active.carousel-item-right {
    transform: translate3d(100%, 0, 0);
  }
}

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%);
}

@supports (transform-style: preserve-3d) {
  .carousel-item-prev,
  .active.carousel-item-left {
    transform: translate3d(-100%, 0, 0);
  }
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-duration: .6s;
  transition-property: opacity;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  opacity: 0;
}

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
  transform: translateX(0);
}

@supports (transform-style: preserve-3d) {
  .carousel-fade .carousel-item-next,
  .carousel-fade .carousel-item-prev,
  .carousel-fade .carousel-item.active,
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-prev {
    transform: translate3d(0, 0, 0);
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0;
  color: #fff;
  text-align: center;
  opacity: 0.5;
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: .9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
  z-index: 9;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 0;
  margin-left: 0;
  list-style: none;
}

.carousel-indicators li {
  position: relative;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.5);
}

.carousel-indicators li::before {
  position: absolute;
  top: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.carousel-indicators li::after {
  position: absolute;
  bottom: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.carousel-indicators .active {
  background-color: #fff;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-dark-blue {
  background-color: #133a72 !important;
}

a.bg-dark-blue:hover, a.bg-dark-blue:focus,
button.bg-dark-blue:hover,
button.bg-dark-blue:focus {
  background-color: #0c2446 !important;
}

.bg-blue {
  background-color: #1a4f9a !important;
}

a.bg-blue:hover, a.bg-blue:focus,
button.bg-blue:hover,
button.bg-blue:focus {
  background-color: #13396e !important;
}

.bg-blue-opacity {
  background-color: rgba(26, 79, 154, 0.25) !important;
}

a.bg-blue-opacity:hover, a.bg-blue-opacity:focus,
button.bg-blue-opacity:hover,
button.bg-blue-opacity:focus {
  background-color: rgba(19, 57, 110, 0.25) !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #d9252b !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ad1e22 !important;
}

.bg-light {
  background-color: #f5f8fc !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #ceddf0 !important;
}

.bg-silver {
  background-color: #8d9fb2 !important;
}

a.bg-silver:hover, a.bg-silver:focus,
button.bg-silver:hover,
button.bg-silver:focus {
  background-color: #6f859d !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-darkest {
  background-color: #282828 !important;
}

a.bg-darkest:hover, a.bg-darkest:focus,
button.bg-darkest:hover,
button.bg-darkest:focus {
  background-color: #0f0f0f !important;
}

.bg-black {
  background-color: #000 !important;
}

a.bg-black:hover, a.bg-black:focus,
button.bg-black:hover,
button.bg-black:focus {
  background-color: black !important;
}

.bg-opacity {
  background-color: rgba(255, 255, 255, 0) !important;
}

a.bg-opacity:hover, a.bg-opacity:focus,
button.bg-opacity:hover,
button.bg-opacity:focus {
  background-color: rgba(230, 230, 230, 0) !important;
}

.bg-opacity-25 {
  background-color: rgba(255, 255, 255, 0.25) !important;
}

a.bg-opacity-25:hover, a.bg-opacity-25:focus,
button.bg-opacity-25:hover,
button.bg-opacity-25:focus {
  background-color: rgba(230, 230, 230, 0.25) !important;
}

.bg-opacity-50 {
  background-color: rgba(255, 255, 255, 0.5) !important;
}

a.bg-opacity-50:hover, a.bg-opacity-50:focus,
button.bg-opacity-50:hover,
button.bg-opacity-50:focus {
  background-color: rgba(230, 230, 230, 0.5) !important;
}

.bg-opacity-75 {
  background-color: rgba(255, 255, 255, 0.75) !important;
}

a.bg-opacity-75:hover, a.bg-opacity-75:focus,
button.bg-opacity-75:hover,
button.bg-opacity-75:focus {
  background-color: rgba(230, 230, 230, 0.75) !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #ecf2f9 !important;
}

.border-top {
  border-top: 1px solid #ecf2f9 !important;
}

.border-right {
  border-right: 1px solid #ecf2f9 !important;
}

.border-bottom {
  border-bottom: 1px solid #ecf2f9 !important;
}

.border-left {
  border-left: 1px solid #ecf2f9 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-dark-blue {
  border-color: #133a72 !important;
}

.border-blue {
  border-color: #1a4f9a !important;
}

.border-blue-opacity {
  border-color: rgba(26, 79, 154, 0.25) !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #d9252b !important;
}

.border-light {
  border-color: #f5f8fc !important;
}

.border-silver {
  border-color: #8d9fb2 !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-darkest {
  border-color: #282828 !important;
}

.border-black {
  border-color: #000 !important;
}

.border-opacity {
  border-color: rgba(255, 255, 255, 0) !important;
}

.border-opacity-25 {
  border-color: rgba(255, 255, 255, 0.25) !important;
}

.border-opacity-50 {
  border-color: rgba(255, 255, 255, 0.5) !important;
}

.border-opacity-75 {
  border-color: rgba(255, 255, 255, 0.75) !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

@media (min-width: 576px) {
  .border-sm {
    border: 1px solid #ecf2f9 !important;
  }
  .border-sm-top {
    border-top: 1px solid #ecf2f9 !important;
  }
  .border-sm-right {
    border-right: 1px solid #ecf2f9 !important;
  }
  .border-sm-bottom {
    border-bottom: 1px solid #ecf2f9 !important;
  }
  .border-sm-left {
    border-left: 1px solid #ecf2f9 !important;
  }
  .border-sm-0 {
    border: 0 !important;
  }
  .border-sm-top-0 {
    border-top: 0 !important;
  }
  .border-sm-right-0 {
    border-right: 0 !important;
  }
  .border-sm-bottom-0 {
    border-bottom: 0 !important;
  }
  .border-sm-left-0 {
    border-left: 0 !important;
  }
  .border-sm-primary {
    border-color: #007bff !important;
  }
  .border-sm-secondary {
    border-color: #6c757d !important;
  }
  .border-sm-success {
    border-color: #28a745 !important;
  }
  .border-sm-info {
    border-color: #17a2b8 !important;
  }
  .border-sm-dark-blue {
    border-color: #133a72 !important;
  }
  .border-sm-blue {
    border-color: #1a4f9a !important;
  }
  .border-sm-blue-opacity {
    border-color: rgba(26, 79, 154, 0.25) !important;
  }
  .border-sm-warning {
    border-color: #ffc107 !important;
  }
  .border-sm-danger {
    border-color: #d9252b !important;
  }
  .border-sm-light {
    border-color: #f5f8fc !important;
  }
  .border-sm-silver {
    border-color: #8d9fb2 !important;
  }
  .border-sm-dark {
    border-color: #343a40 !important;
  }
  .border-sm-darkest {
    border-color: #282828 !important;
  }
  .border-sm-black {
    border-color: #000 !important;
  }
  .border-sm-opacity {
    border-color: rgba(255, 255, 255, 0) !important;
  }
  .border-sm-opacity-25 {
    border-color: rgba(255, 255, 255, 0.25) !important;
  }
  .border-sm-opacity-50 {
    border-color: rgba(255, 255, 255, 0.5) !important;
  }
  .border-sm-opacity-75 {
    border-color: rgba(255, 255, 255, 0.75) !important;
  }
  .border-sm-white {
    border-color: #fff !important;
  }
  .rounded-sm {
    border-radius: 0.25rem !important;
  }
  .rounded-sm-top {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .rounded-sm-right {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .rounded-sm-bottom {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .rounded-sm-left {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .rounded-sm-circle {
    border-radius: 50% !important;
  }
  .rounded-sm-pill {
    border-radius: 50rem !important;
  }
  .rounded-sm-0 {
    border-radius: 0 !important;
  }
}

@media (min-width: 768px) {
  .border-md {
    border: 1px solid #ecf2f9 !important;
  }
  .border-md-top {
    border-top: 1px solid #ecf2f9 !important;
  }
  .border-md-right {
    border-right: 1px solid #ecf2f9 !important;
  }
  .border-md-bottom {
    border-bottom: 1px solid #ecf2f9 !important;
  }
  .border-md-left {
    border-left: 1px solid #ecf2f9 !important;
  }
  .border-md-0 {
    border: 0 !important;
  }
  .border-md-top-0 {
    border-top: 0 !important;
  }
  .border-md-right-0 {
    border-right: 0 !important;
  }
  .border-md-bottom-0 {
    border-bottom: 0 !important;
  }
  .border-md-left-0 {
    border-left: 0 !important;
  }
  .border-md-primary {
    border-color: #007bff !important;
  }
  .border-md-secondary {
    border-color: #6c757d !important;
  }
  .border-md-success {
    border-color: #28a745 !important;
  }
  .border-md-info {
    border-color: #17a2b8 !important;
  }
  .border-md-dark-blue {
    border-color: #133a72 !important;
  }
  .border-md-blue {
    border-color: #1a4f9a !important;
  }
  .border-md-blue-opacity {
    border-color: rgba(26, 79, 154, 0.25) !important;
  }
  .border-md-warning {
    border-color: #ffc107 !important;
  }
  .border-md-danger {
    border-color: #d9252b !important;
  }
  .border-md-light {
    border-color: #f5f8fc !important;
  }
  .border-md-silver {
    border-color: #8d9fb2 !important;
  }
  .border-md-dark {
    border-color: #343a40 !important;
  }
  .border-md-darkest {
    border-color: #282828 !important;
  }
  .border-md-black {
    border-color: #000 !important;
  }
  .border-md-opacity {
    border-color: rgba(255, 255, 255, 0) !important;
  }
  .border-md-opacity-25 {
    border-color: rgba(255, 255, 255, 0.25) !important;
  }
  .border-md-opacity-50 {
    border-color: rgba(255, 255, 255, 0.5) !important;
  }
  .border-md-opacity-75 {
    border-color: rgba(255, 255, 255, 0.75) !important;
  }
  .border-md-white {
    border-color: #fff !important;
  }
  .rounded-md {
    border-radius: 0.25rem !important;
  }
  .rounded-md-top {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .rounded-md-right {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .rounded-md-bottom {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .rounded-md-left {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .rounded-md-circle {
    border-radius: 50% !important;
  }
  .rounded-md-pill {
    border-radius: 50rem !important;
  }
  .rounded-md-0 {
    border-radius: 0 !important;
  }
}

@media (min-width: 992px) {
  .border-lg {
    border: 1px solid #ecf2f9 !important;
  }
  .border-lg-top {
    border-top: 1px solid #ecf2f9 !important;
  }
  .border-lg-right {
    border-right: 1px solid #ecf2f9 !important;
  }
  .border-lg-bottom {
    border-bottom: 1px solid #ecf2f9 !important;
  }
  .border-lg-left {
    border-left: 1px solid #ecf2f9 !important;
  }
  .border-lg-0 {
    border: 0 !important;
  }
  .border-lg-top-0 {
    border-top: 0 !important;
  }
  .border-lg-right-0 {
    border-right: 0 !important;
  }
  .border-lg-bottom-0 {
    border-bottom: 0 !important;
  }
  .border-lg-left-0 {
    border-left: 0 !important;
  }
  .border-lg-primary {
    border-color: #007bff !important;
  }
  .border-lg-secondary {
    border-color: #6c757d !important;
  }
  .border-lg-success {
    border-color: #28a745 !important;
  }
  .border-lg-info {
    border-color: #17a2b8 !important;
  }
  .border-lg-dark-blue {
    border-color: #133a72 !important;
  }
  .border-lg-blue {
    border-color: #1a4f9a !important;
  }
  .border-lg-blue-opacity {
    border-color: rgba(26, 79, 154, 0.25) !important;
  }
  .border-lg-warning {
    border-color: #ffc107 !important;
  }
  .border-lg-danger {
    border-color: #d9252b !important;
  }
  .border-lg-light {
    border-color: #f5f8fc !important;
  }
  .border-lg-silver {
    border-color: #8d9fb2 !important;
  }
  .border-lg-dark {
    border-color: #343a40 !important;
  }
  .border-lg-darkest {
    border-color: #282828 !important;
  }
  .border-lg-black {
    border-color: #000 !important;
  }
  .border-lg-opacity {
    border-color: rgba(255, 255, 255, 0) !important;
  }
  .border-lg-opacity-25 {
    border-color: rgba(255, 255, 255, 0.25) !important;
  }
  .border-lg-opacity-50 {
    border-color: rgba(255, 255, 255, 0.5) !important;
  }
  .border-lg-opacity-75 {
    border-color: rgba(255, 255, 255, 0.75) !important;
  }
  .border-lg-white {
    border-color: #fff !important;
  }
  .rounded-lg {
    border-radius: 0.25rem !important;
  }
  .rounded-lg-top {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .rounded-lg-right {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .rounded-lg-bottom {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .rounded-lg-left {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .rounded-lg-circle {
    border-radius: 50% !important;
  }
  .rounded-lg-pill {
    border-radius: 50rem !important;
  }
  .rounded-lg-0 {
    border-radius: 0 !important;
  }
}

@media (min-width: 1200px) {
  .border-xl {
    border: 1px solid #ecf2f9 !important;
  }
  .border-xl-top {
    border-top: 1px solid #ecf2f9 !important;
  }
  .border-xl-right {
    border-right: 1px solid #ecf2f9 !important;
  }
  .border-xl-bottom {
    border-bottom: 1px solid #ecf2f9 !important;
  }
  .border-xl-left {
    border-left: 1px solid #ecf2f9 !important;
  }
  .border-xl-0 {
    border: 0 !important;
  }
  .border-xl-top-0 {
    border-top: 0 !important;
  }
  .border-xl-right-0 {
    border-right: 0 !important;
  }
  .border-xl-bottom-0 {
    border-bottom: 0 !important;
  }
  .border-xl-left-0 {
    border-left: 0 !important;
  }
  .border-xl-primary {
    border-color: #007bff !important;
  }
  .border-xl-secondary {
    border-color: #6c757d !important;
  }
  .border-xl-success {
    border-color: #28a745 !important;
  }
  .border-xl-info {
    border-color: #17a2b8 !important;
  }
  .border-xl-dark-blue {
    border-color: #133a72 !important;
  }
  .border-xl-blue {
    border-color: #1a4f9a !important;
  }
  .border-xl-blue-opacity {
    border-color: rgba(26, 79, 154, 0.25) !important;
  }
  .border-xl-warning {
    border-color: #ffc107 !important;
  }
  .border-xl-danger {
    border-color: #d9252b !important;
  }
  .border-xl-light {
    border-color: #f5f8fc !important;
  }
  .border-xl-silver {
    border-color: #8d9fb2 !important;
  }
  .border-xl-dark {
    border-color: #343a40 !important;
  }
  .border-xl-darkest {
    border-color: #282828 !important;
  }
  .border-xl-black {
    border-color: #000 !important;
  }
  .border-xl-opacity {
    border-color: rgba(255, 255, 255, 0) !important;
  }
  .border-xl-opacity-25 {
    border-color: rgba(255, 255, 255, 0.25) !important;
  }
  .border-xl-opacity-50 {
    border-color: rgba(255, 255, 255, 0.5) !important;
  }
  .border-xl-opacity-75 {
    border-color: rgba(255, 255, 255, 0.75) !important;
  }
  .border-xl-white {
    border-color: #fff !important;
  }
  .rounded-xl {
    border-radius: 0.25rem !important;
  }
  .rounded-xl-top {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .rounded-xl-right {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .rounded-xl-bottom {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .rounded-xl-left {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .rounded-xl-circle {
    border-radius: 50% !important;
  }
  .rounded-xl-pill {
    border-radius: 50rem !important;
  }
  .rounded-xl-0 {
    border-radius: 0 !important;
  }
}

@media (min-width: 1600px) {
  .border-xxl {
    border: 1px solid #ecf2f9 !important;
  }
  .border-xxl-top {
    border-top: 1px solid #ecf2f9 !important;
  }
  .border-xxl-right {
    border-right: 1px solid #ecf2f9 !important;
  }
  .border-xxl-bottom {
    border-bottom: 1px solid #ecf2f9 !important;
  }
  .border-xxl-left {
    border-left: 1px solid #ecf2f9 !important;
  }
  .border-xxl-0 {
    border: 0 !important;
  }
  .border-xxl-top-0 {
    border-top: 0 !important;
  }
  .border-xxl-right-0 {
    border-right: 0 !important;
  }
  .border-xxl-bottom-0 {
    border-bottom: 0 !important;
  }
  .border-xxl-left-0 {
    border-left: 0 !important;
  }
  .border-xxl-primary {
    border-color: #007bff !important;
  }
  .border-xxl-secondary {
    border-color: #6c757d !important;
  }
  .border-xxl-success {
    border-color: #28a745 !important;
  }
  .border-xxl-info {
    border-color: #17a2b8 !important;
  }
  .border-xxl-dark-blue {
    border-color: #133a72 !important;
  }
  .border-xxl-blue {
    border-color: #1a4f9a !important;
  }
  .border-xxl-blue-opacity {
    border-color: rgba(26, 79, 154, 0.25) !important;
  }
  .border-xxl-warning {
    border-color: #ffc107 !important;
  }
  .border-xxl-danger {
    border-color: #d9252b !important;
  }
  .border-xxl-light {
    border-color: #f5f8fc !important;
  }
  .border-xxl-silver {
    border-color: #8d9fb2 !important;
  }
  .border-xxl-dark {
    border-color: #343a40 !important;
  }
  .border-xxl-darkest {
    border-color: #282828 !important;
  }
  .border-xxl-black {
    border-color: #000 !important;
  }
  .border-xxl-opacity {
    border-color: rgba(255, 255, 255, 0) !important;
  }
  .border-xxl-opacity-25 {
    border-color: rgba(255, 255, 255, 0.25) !important;
  }
  .border-xxl-opacity-50 {
    border-color: rgba(255, 255, 255, 0.5) !important;
  }
  .border-xxl-opacity-75 {
    border-color: rgba(255, 255, 255, 0.75) !important;
  }
  .border-xxl-white {
    border-color: #fff !important;
  }
  .rounded-xxl {
    border-radius: 0.25rem !important;
  }
  .rounded-xxl-top {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .rounded-xxl-right {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .rounded-xxl-bottom {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .rounded-xxl-left {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .rounded-xxl-circle {
    border-radius: 50% !important;
  }
  .rounded-xxl-pill {
    border-radius: 50rem !important;
  }
  .rounded-xxl-0 {
    border-radius: 0 !important;
  }
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1600px) {
  .d-xxl-none {
    display: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1600px) {
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

@media (min-width: 1600px) {
  .float-xxl-left {
    float: left !important;
  }
  .float-xxl-right {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-41 {
  margin: 1.625rem !important;
}

.mt-41,
.my-41 {
  margin-top: 1.625rem !important;
}

.mr-41,
.mx-41 {
  margin-right: 1.625rem !important;
}

.mb-41,
.my-41 {
  margin-bottom: 1.625rem !important;
}

.ml-41,
.mx-41 {
  margin-left: 1.625rem !important;
}

.m-42 {
  margin: 1.75rem !important;
}

.mt-42,
.my-42 {
  margin-top: 1.75rem !important;
}

.mr-42,
.mx-42 {
  margin-right: 1.75rem !important;
}

.mb-42,
.my-42 {
  margin-bottom: 1.75rem !important;
}

.ml-42,
.mx-42 {
  margin-left: 1.75rem !important;
}

.m-43 {
  margin: 1.875rem !important;
}

.mt-43,
.my-43 {
  margin-top: 1.875rem !important;
}

.mr-43,
.mx-43 {
  margin-right: 1.875rem !important;
}

.mb-43,
.my-43 {
  margin-bottom: 1.875rem !important;
}

.ml-43,
.mx-43 {
  margin-left: 1.875rem !important;
}

.m-44 {
  margin: 2rem !important;
}

.mt-44,
.my-44 {
  margin-top: 2rem !important;
}

.mr-44,
.mx-44 {
  margin-right: 2rem !important;
}

.mb-44,
.my-44 {
  margin-bottom: 2rem !important;
}

.ml-44,
.mx-44 {
  margin-left: 2rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-41 {
  padding: 1.625rem !important;
}

.pt-41,
.py-41 {
  padding-top: 1.625rem !important;
}

.pr-41,
.px-41 {
  padding-right: 1.625rem !important;
}

.pb-41,
.py-41 {
  padding-bottom: 1.625rem !important;
}

.pl-41,
.px-41 {
  padding-left: 1.625rem !important;
}

.p-42 {
  padding: 1.75rem !important;
}

.pt-42,
.py-42 {
  padding-top: 1.75rem !important;
}

.pr-42,
.px-42 {
  padding-right: 1.75rem !important;
}

.pb-42,
.py-42 {
  padding-bottom: 1.75rem !important;
}

.pl-42,
.px-42 {
  padding-left: 1.75rem !important;
}

.p-43 {
  padding: 1.875rem !important;
}

.pt-43,
.py-43 {
  padding-top: 1.875rem !important;
}

.pr-43,
.px-43 {
  padding-right: 1.875rem !important;
}

.pb-43,
.py-43 {
  padding-bottom: 1.875rem !important;
}

.pl-43,
.px-43 {
  padding-left: 1.875rem !important;
}

.p-44 {
  padding: 2rem !important;
}

.pt-44,
.py-44 {
  padding-top: 2rem !important;
}

.pr-44,
.px-44 {
  padding-right: 2rem !important;
}

.pb-44,
.py-44 {
  padding-bottom: 2rem !important;
}

.pl-44,
.px-44 {
  padding-left: 2rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-41 {
    margin: 1.625rem !important;
  }
  .mt-sm-41,
  .my-sm-41 {
    margin-top: 1.625rem !important;
  }
  .mr-sm-41,
  .mx-sm-41 {
    margin-right: 1.625rem !important;
  }
  .mb-sm-41,
  .my-sm-41 {
    margin-bottom: 1.625rem !important;
  }
  .ml-sm-41,
  .mx-sm-41 {
    margin-left: 1.625rem !important;
  }
  .m-sm-42 {
    margin: 1.75rem !important;
  }
  .mt-sm-42,
  .my-sm-42 {
    margin-top: 1.75rem !important;
  }
  .mr-sm-42,
  .mx-sm-42 {
    margin-right: 1.75rem !important;
  }
  .mb-sm-42,
  .my-sm-42 {
    margin-bottom: 1.75rem !important;
  }
  .ml-sm-42,
  .mx-sm-42 {
    margin-left: 1.75rem !important;
  }
  .m-sm-43 {
    margin: 1.875rem !important;
  }
  .mt-sm-43,
  .my-sm-43 {
    margin-top: 1.875rem !important;
  }
  .mr-sm-43,
  .mx-sm-43 {
    margin-right: 1.875rem !important;
  }
  .mb-sm-43,
  .my-sm-43 {
    margin-bottom: 1.875rem !important;
  }
  .ml-sm-43,
  .mx-sm-43 {
    margin-left: 1.875rem !important;
  }
  .m-sm-44 {
    margin: 2rem !important;
  }
  .mt-sm-44,
  .my-sm-44 {
    margin-top: 2rem !important;
  }
  .mr-sm-44,
  .mx-sm-44 {
    margin-right: 2rem !important;
  }
  .mb-sm-44,
  .my-sm-44 {
    margin-bottom: 2rem !important;
  }
  .ml-sm-44,
  .mx-sm-44 {
    margin-left: 2rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-41 {
    padding: 1.625rem !important;
  }
  .pt-sm-41,
  .py-sm-41 {
    padding-top: 1.625rem !important;
  }
  .pr-sm-41,
  .px-sm-41 {
    padding-right: 1.625rem !important;
  }
  .pb-sm-41,
  .py-sm-41 {
    padding-bottom: 1.625rem !important;
  }
  .pl-sm-41,
  .px-sm-41 {
    padding-left: 1.625rem !important;
  }
  .p-sm-42 {
    padding: 1.75rem !important;
  }
  .pt-sm-42,
  .py-sm-42 {
    padding-top: 1.75rem !important;
  }
  .pr-sm-42,
  .px-sm-42 {
    padding-right: 1.75rem !important;
  }
  .pb-sm-42,
  .py-sm-42 {
    padding-bottom: 1.75rem !important;
  }
  .pl-sm-42,
  .px-sm-42 {
    padding-left: 1.75rem !important;
  }
  .p-sm-43 {
    padding: 1.875rem !important;
  }
  .pt-sm-43,
  .py-sm-43 {
    padding-top: 1.875rem !important;
  }
  .pr-sm-43,
  .px-sm-43 {
    padding-right: 1.875rem !important;
  }
  .pb-sm-43,
  .py-sm-43 {
    padding-bottom: 1.875rem !important;
  }
  .pl-sm-43,
  .px-sm-43 {
    padding-left: 1.875rem !important;
  }
  .p-sm-44 {
    padding: 2rem !important;
  }
  .pt-sm-44,
  .py-sm-44 {
    padding-top: 2rem !important;
  }
  .pr-sm-44,
  .px-sm-44 {
    padding-right: 2rem !important;
  }
  .pb-sm-44,
  .py-sm-44 {
    padding-bottom: 2rem !important;
  }
  .pl-sm-44,
  .px-sm-44 {
    padding-left: 2rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-41 {
    margin: 1.625rem !important;
  }
  .mt-md-41,
  .my-md-41 {
    margin-top: 1.625rem !important;
  }
  .mr-md-41,
  .mx-md-41 {
    margin-right: 1.625rem !important;
  }
  .mb-md-41,
  .my-md-41 {
    margin-bottom: 1.625rem !important;
  }
  .ml-md-41,
  .mx-md-41 {
    margin-left: 1.625rem !important;
  }
  .m-md-42 {
    margin: 1.75rem !important;
  }
  .mt-md-42,
  .my-md-42 {
    margin-top: 1.75rem !important;
  }
  .mr-md-42,
  .mx-md-42 {
    margin-right: 1.75rem !important;
  }
  .mb-md-42,
  .my-md-42 {
    margin-bottom: 1.75rem !important;
  }
  .ml-md-42,
  .mx-md-42 {
    margin-left: 1.75rem !important;
  }
  .m-md-43 {
    margin: 1.875rem !important;
  }
  .mt-md-43,
  .my-md-43 {
    margin-top: 1.875rem !important;
  }
  .mr-md-43,
  .mx-md-43 {
    margin-right: 1.875rem !important;
  }
  .mb-md-43,
  .my-md-43 {
    margin-bottom: 1.875rem !important;
  }
  .ml-md-43,
  .mx-md-43 {
    margin-left: 1.875rem !important;
  }
  .m-md-44 {
    margin: 2rem !important;
  }
  .mt-md-44,
  .my-md-44 {
    margin-top: 2rem !important;
  }
  .mr-md-44,
  .mx-md-44 {
    margin-right: 2rem !important;
  }
  .mb-md-44,
  .my-md-44 {
    margin-bottom: 2rem !important;
  }
  .ml-md-44,
  .mx-md-44 {
    margin-left: 2rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-41 {
    padding: 1.625rem !important;
  }
  .pt-md-41,
  .py-md-41 {
    padding-top: 1.625rem !important;
  }
  .pr-md-41,
  .px-md-41 {
    padding-right: 1.625rem !important;
  }
  .pb-md-41,
  .py-md-41 {
    padding-bottom: 1.625rem !important;
  }
  .pl-md-41,
  .px-md-41 {
    padding-left: 1.625rem !important;
  }
  .p-md-42 {
    padding: 1.75rem !important;
  }
  .pt-md-42,
  .py-md-42 {
    padding-top: 1.75rem !important;
  }
  .pr-md-42,
  .px-md-42 {
    padding-right: 1.75rem !important;
  }
  .pb-md-42,
  .py-md-42 {
    padding-bottom: 1.75rem !important;
  }
  .pl-md-42,
  .px-md-42 {
    padding-left: 1.75rem !important;
  }
  .p-md-43 {
    padding: 1.875rem !important;
  }
  .pt-md-43,
  .py-md-43 {
    padding-top: 1.875rem !important;
  }
  .pr-md-43,
  .px-md-43 {
    padding-right: 1.875rem !important;
  }
  .pb-md-43,
  .py-md-43 {
    padding-bottom: 1.875rem !important;
  }
  .pl-md-43,
  .px-md-43 {
    padding-left: 1.875rem !important;
  }
  .p-md-44 {
    padding: 2rem !important;
  }
  .pt-md-44,
  .py-md-44 {
    padding-top: 2rem !important;
  }
  .pr-md-44,
  .px-md-44 {
    padding-right: 2rem !important;
  }
  .pb-md-44,
  .py-md-44 {
    padding-bottom: 2rem !important;
  }
  .pl-md-44,
  .px-md-44 {
    padding-left: 2rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-41 {
    margin: 1.625rem !important;
  }
  .mt-lg-41,
  .my-lg-41 {
    margin-top: 1.625rem !important;
  }
  .mr-lg-41,
  .mx-lg-41 {
    margin-right: 1.625rem !important;
  }
  .mb-lg-41,
  .my-lg-41 {
    margin-bottom: 1.625rem !important;
  }
  .ml-lg-41,
  .mx-lg-41 {
    margin-left: 1.625rem !important;
  }
  .m-lg-42 {
    margin: 1.75rem !important;
  }
  .mt-lg-42,
  .my-lg-42 {
    margin-top: 1.75rem !important;
  }
  .mr-lg-42,
  .mx-lg-42 {
    margin-right: 1.75rem !important;
  }
  .mb-lg-42,
  .my-lg-42 {
    margin-bottom: 1.75rem !important;
  }
  .ml-lg-42,
  .mx-lg-42 {
    margin-left: 1.75rem !important;
  }
  .m-lg-43 {
    margin: 1.875rem !important;
  }
  .mt-lg-43,
  .my-lg-43 {
    margin-top: 1.875rem !important;
  }
  .mr-lg-43,
  .mx-lg-43 {
    margin-right: 1.875rem !important;
  }
  .mb-lg-43,
  .my-lg-43 {
    margin-bottom: 1.875rem !important;
  }
  .ml-lg-43,
  .mx-lg-43 {
    margin-left: 1.875rem !important;
  }
  .m-lg-44 {
    margin: 2rem !important;
  }
  .mt-lg-44,
  .my-lg-44 {
    margin-top: 2rem !important;
  }
  .mr-lg-44,
  .mx-lg-44 {
    margin-right: 2rem !important;
  }
  .mb-lg-44,
  .my-lg-44 {
    margin-bottom: 2rem !important;
  }
  .ml-lg-44,
  .mx-lg-44 {
    margin-left: 2rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-41 {
    padding: 1.625rem !important;
  }
  .pt-lg-41,
  .py-lg-41 {
    padding-top: 1.625rem !important;
  }
  .pr-lg-41,
  .px-lg-41 {
    padding-right: 1.625rem !important;
  }
  .pb-lg-41,
  .py-lg-41 {
    padding-bottom: 1.625rem !important;
  }
  .pl-lg-41,
  .px-lg-41 {
    padding-left: 1.625rem !important;
  }
  .p-lg-42 {
    padding: 1.75rem !important;
  }
  .pt-lg-42,
  .py-lg-42 {
    padding-top: 1.75rem !important;
  }
  .pr-lg-42,
  .px-lg-42 {
    padding-right: 1.75rem !important;
  }
  .pb-lg-42,
  .py-lg-42 {
    padding-bottom: 1.75rem !important;
  }
  .pl-lg-42,
  .px-lg-42 {
    padding-left: 1.75rem !important;
  }
  .p-lg-43 {
    padding: 1.875rem !important;
  }
  .pt-lg-43,
  .py-lg-43 {
    padding-top: 1.875rem !important;
  }
  .pr-lg-43,
  .px-lg-43 {
    padding-right: 1.875rem !important;
  }
  .pb-lg-43,
  .py-lg-43 {
    padding-bottom: 1.875rem !important;
  }
  .pl-lg-43,
  .px-lg-43 {
    padding-left: 1.875rem !important;
  }
  .p-lg-44 {
    padding: 2rem !important;
  }
  .pt-lg-44,
  .py-lg-44 {
    padding-top: 2rem !important;
  }
  .pr-lg-44,
  .px-lg-44 {
    padding-right: 2rem !important;
  }
  .pb-lg-44,
  .py-lg-44 {
    padding-bottom: 2rem !important;
  }
  .pl-lg-44,
  .px-lg-44 {
    padding-left: 2rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-41 {
    margin: 1.625rem !important;
  }
  .mt-xl-41,
  .my-xl-41 {
    margin-top: 1.625rem !important;
  }
  .mr-xl-41,
  .mx-xl-41 {
    margin-right: 1.625rem !important;
  }
  .mb-xl-41,
  .my-xl-41 {
    margin-bottom: 1.625rem !important;
  }
  .ml-xl-41,
  .mx-xl-41 {
    margin-left: 1.625rem !important;
  }
  .m-xl-42 {
    margin: 1.75rem !important;
  }
  .mt-xl-42,
  .my-xl-42 {
    margin-top: 1.75rem !important;
  }
  .mr-xl-42,
  .mx-xl-42 {
    margin-right: 1.75rem !important;
  }
  .mb-xl-42,
  .my-xl-42 {
    margin-bottom: 1.75rem !important;
  }
  .ml-xl-42,
  .mx-xl-42 {
    margin-left: 1.75rem !important;
  }
  .m-xl-43 {
    margin: 1.875rem !important;
  }
  .mt-xl-43,
  .my-xl-43 {
    margin-top: 1.875rem !important;
  }
  .mr-xl-43,
  .mx-xl-43 {
    margin-right: 1.875rem !important;
  }
  .mb-xl-43,
  .my-xl-43 {
    margin-bottom: 1.875rem !important;
  }
  .ml-xl-43,
  .mx-xl-43 {
    margin-left: 1.875rem !important;
  }
  .m-xl-44 {
    margin: 2rem !important;
  }
  .mt-xl-44,
  .my-xl-44 {
    margin-top: 2rem !important;
  }
  .mr-xl-44,
  .mx-xl-44 {
    margin-right: 2rem !important;
  }
  .mb-xl-44,
  .my-xl-44 {
    margin-bottom: 2rem !important;
  }
  .ml-xl-44,
  .mx-xl-44 {
    margin-left: 2rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-41 {
    padding: 1.625rem !important;
  }
  .pt-xl-41,
  .py-xl-41 {
    padding-top: 1.625rem !important;
  }
  .pr-xl-41,
  .px-xl-41 {
    padding-right: 1.625rem !important;
  }
  .pb-xl-41,
  .py-xl-41 {
    padding-bottom: 1.625rem !important;
  }
  .pl-xl-41,
  .px-xl-41 {
    padding-left: 1.625rem !important;
  }
  .p-xl-42 {
    padding: 1.75rem !important;
  }
  .pt-xl-42,
  .py-xl-42 {
    padding-top: 1.75rem !important;
  }
  .pr-xl-42,
  .px-xl-42 {
    padding-right: 1.75rem !important;
  }
  .pb-xl-42,
  .py-xl-42 {
    padding-bottom: 1.75rem !important;
  }
  .pl-xl-42,
  .px-xl-42 {
    padding-left: 1.75rem !important;
  }
  .p-xl-43 {
    padding: 1.875rem !important;
  }
  .pt-xl-43,
  .py-xl-43 {
    padding-top: 1.875rem !important;
  }
  .pr-xl-43,
  .px-xl-43 {
    padding-right: 1.875rem !important;
  }
  .pb-xl-43,
  .py-xl-43 {
    padding-bottom: 1.875rem !important;
  }
  .pl-xl-43,
  .px-xl-43 {
    padding-left: 1.875rem !important;
  }
  .p-xl-44 {
    padding: 2rem !important;
  }
  .pt-xl-44,
  .py-xl-44 {
    padding-top: 2rem !important;
  }
  .pr-xl-44,
  .px-xl-44 {
    padding-right: 2rem !important;
  }
  .pb-xl-44,
  .py-xl-44 {
    padding-bottom: 2rem !important;
  }
  .pl-xl-44,
  .px-xl-44 {
    padding-left: 2rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1600px) {
  .m-xxl-0 {
    margin: 0 !important;
  }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important;
  }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important;
  }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important;
  }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important;
  }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xxl-41 {
    margin: 1.625rem !important;
  }
  .mt-xxl-41,
  .my-xxl-41 {
    margin-top: 1.625rem !important;
  }
  .mr-xxl-41,
  .mx-xxl-41 {
    margin-right: 1.625rem !important;
  }
  .mb-xxl-41,
  .my-xxl-41 {
    margin-bottom: 1.625rem !important;
  }
  .ml-xxl-41,
  .mx-xxl-41 {
    margin-left: 1.625rem !important;
  }
  .m-xxl-42 {
    margin: 1.75rem !important;
  }
  .mt-xxl-42,
  .my-xxl-42 {
    margin-top: 1.75rem !important;
  }
  .mr-xxl-42,
  .mx-xxl-42 {
    margin-right: 1.75rem !important;
  }
  .mb-xxl-42,
  .my-xxl-42 {
    margin-bottom: 1.75rem !important;
  }
  .ml-xxl-42,
  .mx-xxl-42 {
    margin-left: 1.75rem !important;
  }
  .m-xxl-43 {
    margin: 1.875rem !important;
  }
  .mt-xxl-43,
  .my-xxl-43 {
    margin-top: 1.875rem !important;
  }
  .mr-xxl-43,
  .mx-xxl-43 {
    margin-right: 1.875rem !important;
  }
  .mb-xxl-43,
  .my-xxl-43 {
    margin-bottom: 1.875rem !important;
  }
  .ml-xxl-43,
  .mx-xxl-43 {
    margin-left: 1.875rem !important;
  }
  .m-xxl-44 {
    margin: 2rem !important;
  }
  .mt-xxl-44,
  .my-xxl-44 {
    margin-top: 2rem !important;
  }
  .mr-xxl-44,
  .mx-xxl-44 {
    margin-right: 2rem !important;
  }
  .mb-xxl-44,
  .my-xxl-44 {
    margin-bottom: 2rem !important;
  }
  .ml-xxl-44,
  .mx-xxl-44 {
    margin-left: 2rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important;
  }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important;
  }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important;
  }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important;
  }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important;
  }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important;
  }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xxl-41 {
    padding: 1.625rem !important;
  }
  .pt-xxl-41,
  .py-xxl-41 {
    padding-top: 1.625rem !important;
  }
  .pr-xxl-41,
  .px-xxl-41 {
    padding-right: 1.625rem !important;
  }
  .pb-xxl-41,
  .py-xxl-41 {
    padding-bottom: 1.625rem !important;
  }
  .pl-xxl-41,
  .px-xxl-41 {
    padding-left: 1.625rem !important;
  }
  .p-xxl-42 {
    padding: 1.75rem !important;
  }
  .pt-xxl-42,
  .py-xxl-42 {
    padding-top: 1.75rem !important;
  }
  .pr-xxl-42,
  .px-xxl-42 {
    padding-right: 1.75rem !important;
  }
  .pb-xxl-42,
  .py-xxl-42 {
    padding-bottom: 1.75rem !important;
  }
  .pl-xxl-42,
  .px-xxl-42 {
    padding-left: 1.75rem !important;
  }
  .p-xxl-43 {
    padding: 1.875rem !important;
  }
  .pt-xxl-43,
  .py-xxl-43 {
    padding-top: 1.875rem !important;
  }
  .pr-xxl-43,
  .px-xxl-43 {
    padding-right: 1.875rem !important;
  }
  .pb-xxl-43,
  .py-xxl-43 {
    padding-bottom: 1.875rem !important;
  }
  .pl-xxl-43,
  .px-xxl-43 {
    padding-left: 1.875rem !important;
  }
  .p-xxl-44 {
    padding: 2rem !important;
  }
  .pt-xxl-44,
  .py-xxl-44 {
    padding-top: 2rem !important;
  }
  .pr-xxl-44,
  .px-xxl-44 {
    padding-right: 2rem !important;
  }
  .pb-xxl-44,
  .py-xxl-44 {
    padding-bottom: 2rem !important;
  }
  .pl-xxl-44,
  .px-xxl-44 {
    padding-left: 2rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3rem !important;
  }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important;
  }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important;
  }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1600px) {
  .text-xxl-left {
    text-align: left !important;
  }
  .text-xxl-right {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-semibold {
  font-weight: 500 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.font-size-x-small {
  font-size: 0.75rem;
}

.font-size-small {
  font-size: 0.875rem;
}

.font-size-normal {
  font-size: 1rem;
}

.font-size-medium {
  font-size: 1.125rem;
  line-height: 120%;
}

.font-size-large {
  font-size: 1.25rem;
}

.font-size-x-large {
  font-size: 1.5rem;
}

.font-size-x-large-2 {
  font-size: 2rem;
}

.font-size-x-large-3 {
  font-size: 2.25rem;
  line-height: 120%;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0062cc !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #545b62 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #1e7e34 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important;
}

.text-dark-blue {
  color: #133a72 !important;
}

a.text-dark-blue:hover, a.text-dark-blue:focus {
  color: #0c2446 !important;
}

.text-blue {
  color: #1a4f9a !important;
}

a.text-blue:hover, a.text-blue:focus {
  color: #13396e !important;
}

.text-blue-opacity {
  color: rgba(26, 79, 154, 0.25) !important;
}

a.text-blue-opacity:hover, a.text-blue-opacity:focus {
  color: rgba(19, 57, 110, 0.25) !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important;
}

.text-danger {
  color: #d9252b !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #ad1e22 !important;
}

.text-light {
  color: #f5f8fc !important;
}

a.text-light:hover, a.text-light:focus {
  color: #ceddf0 !important;
}

.text-silver {
  color: #8d9fb2 !important;
}

a.text-silver:hover, a.text-silver:focus {
  color: #6f859d !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important;
}

.text-darkest {
  color: #282828 !important;
}

a.text-darkest:hover, a.text-darkest:focus {
  color: #0f0f0f !important;
}

.text-black {
  color: #000 !important;
}

a.text-black:hover, a.text-black:focus {
  color: black !important;
}

.text-opacity {
  color: rgba(255, 255, 255, 0) !important;
}

a.text-opacity:hover, a.text-opacity:focus {
  color: rgba(230, 230, 230, 0) !important;
}

.text-opacity-25 {
  color: rgba(255, 255, 255, 0.25) !important;
}

a.text-opacity-25:hover, a.text-opacity-25:focus {
  color: rgba(230, 230, 230, 0.25) !important;
}

.text-opacity-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

a.text-opacity-50:hover, a.text-opacity-50:focus {
  color: rgba(230, 230, 230, 0.5) !important;
}

.text-opacity-75 {
  color: rgba(255, 255, 255, 0.75) !important;
}

a.text-opacity-75:hover, a.text-opacity-75:focus {
  color: rgba(230, 230, 230, 0.75) !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-overline {
  text-decoration: overline;
}

.text-line-through {
  text-decoration: line-through;
}

.text-underline {
  text-decoration: underline;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

/*# sourceMappingURL=bootstrap.css.map */